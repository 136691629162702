<template>
    <div>
        <a-button size="small" type="primary" @click="add">添加</a-button>
        <a-modal class="modal-form" title="添加" width="70%" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" label-align="left" layout="inline">
                <a-row type="flex" justify="space-between">
                    <a-form-model-item label="物流平台" prop="platformId">
                        <v-select v-model="form.platform_id" :reduce="option=>option.id" :options="platforms" placeholder="请选择物流平台" @option:selected="refreshMethod" style="min-width:200px;" label="name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                    <a-form-model-item label="目标国家" prop="country">
                        <v-select v-model="form.country" :reduce="option=>option.code" :options="country" placeholder="请选择目标国家" @option:selected="refreshMethod" style="min-width:200px;" :get-option-label="e=>e.code+' - '+e.name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                    <a-form-model-item label="运输方式" prop="methodCode">
                        <v-select v-model="form.method_code" :reduce="option=>option.code" :options="methods" placeholder="请选择运输方式" :disabled="!form.country" style="min-width:250px;" label="name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                </a-row>
                <br />
                <a-card title="收件人" size="small">
                    <a-row type="flex" justify="space-between">
                        <a-form-model-item label="国家" prop="country">
                            <a-input v-model="form.receiver.country" disabled placeholder="请选择收件人国家" />
                        </a-form-model-item>
                        <a-form-model-item label="省份" prop="province">
                            <a-input v-model="form.receiver.province" placeholder="请输入省份" />
                        </a-form-model-item>
                        <a-form-model-item label="城市" prop="city">
                            <a-input v-model="form.receiver.city" placeholder="请输入城市" />
                        </a-form-model-item>
                        <a-form-model-item label="地址1" prop="street">
                            <a-textarea v-model="form.receiver.street" placeholder="请输入街道" />
                        </a-form-model-item>
                        <a-form-model-item label="地址2" prop="addr">
                            <a-textarea v-model="form.receiver.addr" placeholder="请输入地址" />
                        </a-form-model-item>
                        <a-form-model-item label="邮编" prop="zip">
                            <a-input v-model="form.receiver.zip" placeholder="请输入邮编" />
                        </a-form-model-item>
                        <a-form-model-item label="姓名" prop="name">
                            <a-input v-model="form.receiver.name" placeholder="请输入姓名" />
                        </a-form-model-item>
                        <a-form-model-item label="联系方式" prop="phone">
                            <a-input v-model="form.receiver.phone" placeholder="请输入联系方式" />
                        </a-form-model-item>
                        <a-form-model-item label="邮箱" prop="email">
                            <a-input v-model="form.receiver.email" placeholder="请输入邮箱" />
                        </a-form-model-item>
                        <a-form-model-item label="售价" prop="price">
                            <a-input-number :min="0" :step="0.01" v-model="form.price" />
                        </a-form-model-item>
                        <a-form-model-item label="备注" :wrapper-col="{ width: '80%' }">
                            <a-textarea placeholder="请输入备注信息" :rows="2" v-model="form.remark" />
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <br />
                <a-card title="产品" size="small">
                    <a-space size="small" style="margin-bottom:10px">
                        <a-button size="small" type="primary" @click="addGoods">添加</a-button>
                    </a-space>
                    <a-table bordered size="small" :columns="columns" :data-source="form.goods" :rowKey="record => form.goods.indexOf(record)">
                        <template slot="images" slot-scope="text, record" class="file-upload">
                            <file-upload accept=".jpg,.png,.jpeg" listType="picture-card" multiple class="file-upload" v-model="record.images">
                                <a-icon type="upload" v-if="!record.images || record.images.length < 5" />
                            </file-upload>
                        </template>
                        <template slot="EName" slot-scope="text, record">
                            <a-input v-model="record.EName" placeholder="请输入英文名称" style="width:70px;"></a-input>
                        </template>
                        <template slot="CName" slot-scope="text, record">
                            <a-input v-model="record.CName" placeholder="请输入中文名称" style="width:70px;"></a-input>
                        </template>
                        <template slot="postIds" slot-scope="text, record">
                            <a-popover title="点击相应订单号查询物流状态（输入后请回车）">
                                <span slot="content" class="post-list">
                                    <a-tag color="cyan" v-for="(item, index) in record.postIds" :key="index" closable @close="removePostId($event, record, item)" @click="clickPostId(item)">
                                        {{ item }}
                                    </a-tag>
                                </span>
                                <a-input placeholder="请输入国内单号" v-model="record.postId" @pressEnter="addPostId(record)" />
                            </a-popover>
                        </template>
                        <template slot="IOSS" slot-scope="text, record">
                            <a-input v-model="record.IOSS" placeholder="请输入IOSS" style="width:70px;"></a-input>
                        </template>
                        <template slot="quantity" slot-scope="text, record">
                            <a-input-number :min="0" v-model="record.quantity" />
                        </template>
                        <template slot="currencyCode" slot-scope="text, record">
                            <a-input v-model="record.currencyCode"></a-input>
                        </template>
                        <template slot="price" slot-scope="text, record">
                            <a-input-number :min="0" :step="0.01" v-model="record.price" />
                        </template>
                        <template slot="weight" slot-scope="text, record">
                            <a-input-number :min="0" :step="0.01" v-model="record.weight" />
                        </template>
                        <template slot="remark" slot-scope="text, record">
                            <a-input v-model="record.remark"></a-input>
                        </template>
                        <template slot="action" slot-scope="text, record">
                            <a-space size="small">
                                <a-popconfirm title="是否确定删除？" @confirm="delGoods(record)">
                                    <a-button size="small" type="danger">删除</a-button>
                                </a-popconfirm>
                            </a-space>
                        </template>
                    </a-table>
                </a-card>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
import { mapGetters } from "vuex";
export default {
    components: { FileUpload },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                receiver: {},
                goods: [],
            },
            methods: [],
            platforms: [],
            postId: "",
            rules: {
                platform_id: [
                    {
                        required: true,
                        message: "平台必选",
                    },
                ],
            },
            columns: [
                {
                    title: "图片",
                    key: "images",
                    dataIndex: "images",
                    scopedSlots: { customRender: "images" },
                },
                {
                    title: "IOSS",
                    dataIndex: "IOSS",
                    align: "center",
                    scopedSlots: { customRender: "IOSS" },
                },
                {
                    title: "英文名称",
                    dataIndex: "EName",
                    scopedSlots: { customRender: "EName" },
                },
                {
                    title: "中文名称",
                    dataIndex: "CName",
                    scopedSlots: { customRender: "CName" },
                },
                {
                    title: "国内物流",
                    dataIndex: "postIds",
                    scopedSlots: { customRender: "postIds" },
                },
                {
                    title: "数量",
                    dataIndex: "quantity",
                    scopedSlots: {
                        customRender: "quantity",
                    },
                },
                {
                    title: "币种",
                    dataIndex: "currencyCode",
                    scopedSlots: {
                        customRender: "currencyCode",
                    },
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    scopedSlots: {
                        customRender: "price",
                    },
                },
                {
                    title: "重量（KG）",
                    dataIndex: "weight",
                    scopedSlots: {
                        customRender: "weight",
                    },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    scopedSlots: {
                        customRender: "remark",
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 80,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["country"]),
    },
    methods: {
        add() {
            this.visible = true;
            this.api.get("platform/simple").then((res) => {
                this.platforms = res.data || [];
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.post("order", this.form).then(() => {
                        this.$message.success("添加成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        addGoods() {
            this.form.goods.push({
                EName: "",
                CName: "",
                quantity: 1,
                currencyCode: "USD",
                price: 0,
                weight: 0,
                postIds: [],
            });
        },
        delGoods(record) {
            let goods = this.form.goods;
            let index = goods.indexOf(record);
            goods.splice(index, 1);
        },
        addPostId(record) {
            if (record.postId) {
                if (record.postIds.indexOf(record.postId) < 0) {
                    record.postIds.push(record.postId);
                    record.postId = "";
                } else {
                    this.$message.warning("当前订单号已存在");
                }
            }
        },
        removePostId(e, record, value) {
            let index = record.postIds.indexOf(value);
            record.postIds.splice(index, 1);
            e.preventDefault();
        },
        clickPostId(value) {
            window.open(`https://www.baidu.com/s?wd=${value}`);
        },
        refreshMethod() {
            if (this.form.platform_id && this.form.country) {
                this.form.receiver.country = this.form.country;
                this.api
                    .get(
                        `dispatch/methods/user?platform=${this.form.platform_id}&country=${this.form.country}`
                    )
                    .then((res) => {
                        this.methods = res.data;
                    });
            }
        },
    },
};
</script>
<style scoped lang="less">
.ant-select {
    min-width: 180px;
}

.ant-form-item {
    margin-right: unset;
}

.file-upload {
    /deep/ .ant-upload {
        width: 48px;
        height: 48px;
    }

    /deep/ .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 2px;
    }

    /deep/ .ant-upload-list-picture-card-container {
        width: unset;
        height: unset;
        margin: unset;
    }
}

.post-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tag {
        margin-bottom: 5px;
        user-select: none;
    }
}
</style>