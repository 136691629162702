import store from '@/store'
export default {
    hasRole(value) {
        let role = store.getters.roleTag;
        let has = false;
        if (value.constructor === Array) {
            has = value.includes(role)
        } else if (value.constructor === String) {
            has = value == role
        }
        return has
    },
    hasPermissions(value) {
        let permissions = store.getters.permissions;
        return permissions.includes(value)
    },
    suffix(name) {
        let arr = name.split(".");
        return arr[arr.length - 1]
    },
    randomString(len) {
        len = len || 32;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = '';
        for (let i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    format(value = Date.now(), format = "Y-M-D h:m:s") {
        const formatNumber = n => `0${n}`.slice(-2);
        const date = new Date(value);
        const formatList = ["Y", "M", "D", "h", "m", "s"];
        const resultList = [];
        resultList.push(date.getFullYear().toString());
        resultList.push(formatNumber(date.getMonth() + 1));
        resultList.push(formatNumber(date.getDate()));
        resultList.push(formatNumber(date.getHours()));
        resultList.push(formatNumber(date.getMinutes()));
        resultList.push(formatNumber(date.getSeconds()));
        for (let i = 0; i < resultList.length; i++) {
            format = format.replace(formatList[i], resultList[i]);
        }
        return format;
    }
}