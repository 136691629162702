import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth';
import dispatch from './modules/dispatch';
import storage from './modules/storage';
import local from './modules/local';

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        auth,
        local,
        dispatch,
        storage
    },
    state: {
        loading: false
    },
    mutations: {
        loading(state, value) {
            state.loading = value;
        }
    },
    actions: {
        init({ dispatch }) {
            ['platforms', 'country', 'order_status'].forEach(name => {
                dispatch(name)
            })
        }
    }
})
