<template>
    <div>
        <div @click="add">
            <slot>
                <a-button size="small" type="primary">添加</a-button>
            </slot>
        </div>
        <a-modal class="modal-form" title="申请工单" width="50%" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
                <a-form-model-item v-if="order" label="国际物流单号" prop="tracking_number">
                    <a-input v-model="form.tracking_number" disabled placeholder="国际物流单号" />
                </a-form-model-item>
                <a-form-model-item v-if="!order&&!receive" label="用户" prop="tag">
                    <a-input-search v-if="!user.id" placeholder="输入用户名/手机号/用户标记进行搜索" enter-button @search="searchUser" />
                    <h3 v-if="user.id">找到用户：{{user.username}} <span>{{ user.shortId }}</span></h3>
                </a-form-model-item>
                <a-form-model-item label="标签" prop="tag">
                    <a-select v-model="form.tag" class="tags" mode="multiple" placeholder="请选择工单标签" allow-clear :filter-option="tagFilter" show-arrow>
                        <a-select-option v-for="(item, index) in work_server_tags" :key="index">
                            <a-tag :tag="item.title" :color="item.color">{{ item.title }}</a-tag>
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="问题描述" prop="content">
                    <a-textarea placeholder="请输入问题描述" :rows="6" v-model="form.content" />
                </a-form-model-item>
                <a-form-model-item label="附件" prop="images">
                    <file-upload accept=".jpg,.png,.jpeg" listType="picture-card" multiple class="file-upload" v-model="form.images">
                        <a-icon type="upload" v-if="!form.images || form.images.length < 5" />
                    </file-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
import { mapGetters } from "vuex";
export default {
    components: { FileUpload },
    props: {
        order: {
            type: Object,
            required: false,
        },
        receive: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                tracking_number: "",
                images: [],
                content: "",
                tag: [],
            },
            user: {},
            postId: "",
        };
    },
    computed: {
        ...mapGetters(["work_server_tags", "roleTag"]),
    },
    methods: {
        add() {
            if (this.order) {
                this.form.order_id = this.order.id;
                this.form.platform_id = this.order.platformId;
                this.form.country = this.order.country;
                this.form.method_code = this.order.methodCode;
                this.form.tracking_number = this.order.wayBillNumber;
                this.form.user_id = this.order.createBy;
            } else if (this.receive) {
                this.form.postId = this.receive.postId;
                this.form.user_id = this.receive.createBy;
            } else if (this.roleTag == "USER") {
                this.user = this.$store.state.auth.user;
            }
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.post("work_server", this.form).then(() => {
                        this.$message.success("添加成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        searchUser(text) {
            this.api.get(`user/search?keyword=${text}`).then((res) => {
                this.user = res.data;
                this.form.user_id = this.user.id;
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        tagFilter(text, option) {
            return (
                option.componentOptions.children[0].data.attrs.tag
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            );
        },
    },
};
</script>
<style scoped lang="less">
.ant-select {
    min-width: 180px;
}

.ant-form-item {
    margin-right: unset;
}

.file-upload {
    /deep/ .ant-upload {
        width: 48px;
        height: 48px;
    }

    /deep/ .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 2px;
    }

    /deep/ .ant-upload-list-picture-card-container {
        width: unset;
        height: unset;
        margin: unset;
    }
}

.post-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tag {
        margin-bottom: 5px;
        user-select: none;
    }
}

.tags {
    /deep/ .ant-select-selection__choice {
        border: unset;
        background-color: unset;
        padding: unset;
    }

    /deep/ .ant-tag {
        margin-right: 0px;
        padding: 0px 20px 0px 7px;
    }
}
</style>