<template>
    <base-page>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add @close="refresh"></add>
            <batch-edit :selectedRows="selectedRows" @close="refresh(true)"></batch-edit>
        </a-space>
        <div style="margin-bottom:10px;">
            <a-form-model layout="inline" :model="search">
                <a-form-model-item label="物流平台">
                    <v-select v-model="search.platform" :reduce="option=>option.platform" :options="platformList" @option:selected="platformChange" placeholder="请选择物流平台" style="width:200px;" label="name">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="国家" prop="country">
                    <v-select v-model="search.country" :reduce="option=>option.code" :options="country" placeholder="请选择目标国家" @option:selected="refreshMethod" :get-option-label="e=>e.code+' - '+e.name" style="width:200px;">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="运输方式" prop="methods">
                    <v-select v-model="search.method" :reduce="option=>option.code" :options="methods" placeholder="请选择运输方式" :disabled="!search.country" style="width:200px;" label="name">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="用户标签" prop="userTags">
                    <v-select v-model="search.userTag" :options="tags" placeholder="请选择用户标签" style="width:200px;">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="refresh" htmlType="submit">查询</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
        <a-table bordered size="small" :rowKey="x => x.id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <span slot="platform" slot-scope="text, record">
                {{ platforms[record.platform] }}
            </span>
            <span slot="discount" slot-scope="text">
                {{ text }}%
            </span>
            <span slot="enable" slot-scope="text, record">
                <a-switch v-model="record.enable" disabled />
            </span>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <edit :id="record.id" @close="refresh"></edit>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import add from "./Add.vue";
import edit from "./Edit.vue";
import BatchEdit from "./BatchEdit.vue";
import { mapGetters } from "vuex";
export default {
    components: { add, edit, BatchEdit },
    data() {
        return {
            data: [],
            search: {},
            tags: [],
            methods: [],
            platformList: [],
            platformId: 0,
            selectedRows: [],
            columns: [
                {
                    title: "名称",
                    dataIndex: "name",
                },
                {
                    title: "平台",
                    dataIndex: "platform",
                    scopedSlots: { customRender: "platform" },
                },
                {
                    title: "国家",
                    dataIndex: "country",
                },
                {
                    title: "运输方式",
                    dataIndex: "methodName",
                },
                {
                    title: "用户标签",
                    dataIndex: "userTag",
                },
                {
                    title: "扣费比例",
                    dataIndex: "discount",
                    scopedSlots: { customRender: "discount" },
                },
                {
                    title: "启用",
                    dataIndex: "enable",
                    scopedSlots: { customRender: "enable" },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
        };
    },
    computed: {
        ...mapGetters(["country", "platforms"]),
        rowSelection() {
            let that = this;
            return {
                fixed: true,
                onChange(selectedRowKeys) {
                    that.selectedRows = selectedRowKeys;
                },
            };
        },
    },
    async created() {
        this.tags = (await this.api.get("user/tags")).data;
        this.platformList = (await this.api.get("platform/simple")).data;
        this.refresh(true);
    },
    methods: {
        del(record) {
            this.api.delete(`channel/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("channel/search", this.pagination, this.search)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        platformChange(e) {
            this.platformId = e.id;
            this.refreshMethod();
        },
        refreshMethod() {
            if (this.platformId && this.search.country) {
                this.api
                    .get(
                        `dispatch/methods?platform=${this.platformId}&country=${this.search.country}`
                    )
                    .then((res) => {
                        this.methods = res.data;
                    });
            }
        },
    },
};
</script>
