import role from "./role"
import permission from "./permission"

const directives = {
    role,
    permission
}

export default {
    install(Vue) {
        Object.keys(directives).forEach(key => {
            Vue.directive(key, directives[key])
        })
    }
}
