<template>
    <base-page>
        <a-form-model layout="horizontal" ref="form" :model="form" :rules="rules">
            <a-form-model-item label="时间范围" prop="data.timeRange">
                <a-range-picker v-model="form.data.timeRange" allowClear :ranges="ranges" />
            </a-form-model-item>
        </a-form-model>
        <a-list bordered item-layout="horizontal" :data-source="showList">
            <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta :description="item.desc">
                    <h3 slot="title">{{ item.name }}</h3>
                </a-list-item-meta>
                <a-button slot="actions" type="primary" icon="export" @click="download(item)">导出</a-button>
            </a-list-item>
        </a-list>
    </base-page>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            form: {
                data: {
                    timeRange: [moment(), moment()],
                },
            },
            rules: {
                "data.timeRange": {
                    required: true,
                    message: "时间范围必选",
                },
            },
            list: [
                {
                    name: "用户列表",
                    desc: "本功能可一次性导出所有用户，包含全部用户，可利用表格筛选功能查看每段时间内新增或减少用户。",
                    url: "statistics/user",
                },
                {
                    name: "订单明细",
                    desc: "本功能可导出用户出单历史，以分析用户出单情况。",
                    url: "statistics/order",
                },
                {
                    name: "充值记录",
                    desc: "本功能导出系统某个时间内的充值记录统计表。",
                    url: "statistics/recharge",
                },
                {
                    name: "消费记录",
                    desc: "本功能导出系统某个时间内的消费记录统计表。",
                    url: "statistics/pay",
                },
                {
                    name: "仓库统计",
                    desc: "本功能导出系统某个时间内的仓库统计表。",
                    url: "statistics/warehouse",
                },
                {
                    name: "财务统计",
                    desc: "本功能导出系统某个时间内的财务统计表。",
                    url: "statistics/finance",
                },
            ],
            ranges: {
                近一年: [moment().add(-1, "year"), moment()],
                近一月: [moment().add(-1, "month"), moment()],
                近一周: [moment().add(-1, "week"), moment()],
                今天: [moment(), moment()],
            },
        };
    },
    computed: {
        ...mapGetters(["user"]),
        showList() {
            let list = this.list;
            let tag = this.user.role.tag;
            if (["USER"].includes(tag)) {
                list = this.list.filter((item, index) =>
                    [1, 2, 3].includes(index)
                );
            }
            return list;
        },
    },
    methods: {
        download(item) {
            let fun = () => {
                this.api.download(item.url, this.form);
            };
            if (["交易明细"].includes(item.name)) {
                fun();
            } else {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        fun();
                    }
                });
            }
        },
    },
};
</script>
<style scoped lang="less">
</style>
