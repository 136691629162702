<template>
    <div>
        <a-button size="small" type="primary" @click="add">添加</a-button>
        <a-modal title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="标题" prop="title">
                    <a-input v-model="form.title" />
                </a-form-model-item>
                <a-form-model-item label="级别" prop="noticeLevel">
                    <a-select v-model="form.noticeLevel">
                        <a-select-option v-for="(item,index) in levels" :key="index" :value="index">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                 <a-form-model-item label="自动弹出" prop="popup">
                     <a-checkbox v-model="form.popup"></a-checkbox>
                </a-form-model-item>
                <a-form-model-item label="内容" prop="body">
                    <a-textarea v-model="form.body" :rows="4" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {

    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {},
            levels: ['一般', '警告', '危险'],
            rules: {
                title: [
                    {
                        required: true,
                        message: "标题必填",
                    },
                ],
                body: [
                    {
                        required: true,
                        message: "内容必填",
                    },
                ]
            }
        };
    },
    methods: {
        add() {
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.api.post('notice', this.form).then(() => {
                        this.$message.success('添加成功');
                        this.$refs.form.resetFields();
                        this.visible = false;
                    })
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        }
    }
};
</script>
