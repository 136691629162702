<template>
    <base-page>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add v-if="0" v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]'></add>
        </a-space>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <template slot="images" slot-scope="text, record">
                <preview-image :src="record.images"></preview-image>
            </template>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <a-button size="small" type="primary" v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]' @click="openPrint(record)">打印标签</a-button>
                    <a-button size="small" @click="showLog(record)">查看记录</a-button>
                </a-space>
            </span>
        </a-table>
        <a-modal v-model="stockHistoryVisible" destroyOnClose title="操作记录" @ok="submit">
            <a-space size="small" direction="vertical" style="width:100%;">
                <a-space size="small" v-role='["SYSTEM","ADMIN", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]'>
                    <div>货架号：</div>
                    <a-input v-model="stock.shelves" placeholder="请输入货架号" />
                </a-space>
                <a-space size="small">
                    <div>备&nbsp;&nbsp;&nbsp;注：</div>
                    <a-textarea v-model="stock.remark" placeholder="请输入备注" :disabled='!["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"].includes(roleTag)' />
                </a-space>
                <a-table bordered size="small" :columns="stockColumns" :data-source="stock.history" :pagination="false" :rowKey="record => stock.history.indexOf(record)">
                    <template slot="number" slot-scope="text, record">
                        <a-input-number :min="1" :step="1" v-model="record.number" :disabled='!["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"].includes(roleTag)' />
                    </template>
                    <template slot="add" slot-scope="text, record">
                        {{ record.add?'增加':'减少' }}
                    </template>
                </a-table>
            </a-space>
        </a-modal>
        <a-modal title="标签打印" :visible="printVisible" @cancel="printVisible=false" destroyOnClose>
            <div id="stock-print" style="margin:auto;display:table;border:1px solid black;">
                <div style="margin:10px 0px 0px 10px;font-weight: bold">
                    <div>用户：{{printData.username||'--'}}</div>
                    <div>日期：{{printData.createTime}}</div>
                </div>
                <h1 style="margin:auto;display: table;font-weight: bold;font-size:xxx-large;">{{printData.shelves}}</h1>
                <barcode :value="printData.sku" :width="1" :height="30" style="margin:auto;display:table;"></barcode>
            </div>
            <template slot="footer">
                <a-button type="primary" v-print="printOptions">打印</a-button>
            </template>
        </a-modal>
    </base-page>
</template>
<script>
import Add from "./Add.vue";
import Barcode from "vue-barcode";
import { mapGetters } from "vuex";
import PreviewImage from "@/components/PreviewImage.vue";
export default {
    components: {
        Add,
        Barcode,
        PreviewImage,
    },
    data() {
        return {
            data: [],
            form: {},
            stock: {},
            stockHistoryVisible: false,
            columns: [
                {
                    title: "图片",
                    dataIndex: "images",
                    scopedSlots: {
                        customRender: "images",
                    },
                },
                {
                    title: "SKU",
                    dataIndex: "sku",
                },
                {
                    title: "货架号",
                    dataIndex: "shelves",
                },
                {
                    title: "库存量",
                    dataIndex: "stock",
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                },
                {
                    title: "所属人",
                    dataIndex: "username",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            stockColumns: [
                {
                    title: "时间",
                    dataIndex: "time",
                },
                {
                    title: "用户",
                    dataIndex: "username",
                },
                {
                    title: "操作",
                    dataIndex: "add",
                    scopedSlots: { customRender: "add" },
                },
                {
                    title: "数量",
                    dataIndex: "number",
                    scopedSlots: { customRender: "number" },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
            printData: {},
            printVisible: false,
            printOptions: {
                id: "stock-print",
            },
        };
    },
    computed: {
        ...mapGetters(["roleTag"]),
    },
    created() {
        this.refresh(true);
    },
    methods: {
        del() {},
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("stock/search", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        showLog(record) {
            this.api.get(`stock/${record.id}`).then((res) => {
                this.stock = res.data;
                this.stockHistoryVisible = true;
            });
        },
        submit() {
            this.api.put(`stock`, this.stock).then((res) => {
                this.stock = res.data;
                this.stockHistoryVisible = false;
                this.$message.info("保存成功");
                this.refresh();
            });
        },
        openPrint(record) {
            this.printData = record;
            this.printVisible = true;
        },
    },
};
</script>
<style scoped>
.ant-table-tbody .ant-table-row {
    height: 20px;
}
</style>
