<template>
    <div class="container">
        <a-result v-bind="result">
            <template #extra>
                <a-button v-if="result.status!=='status'" type="primary" @click="$router.push('/')">返回系统</a-button>
            </template>
        </a-result>
    </div>
</template>
<script>
export default {
    data() {
        return {
            result: {
                status: "info",
                title: "授权中...",
                subTitle: "正在获取授权状态，请稍后...",
            },
        };
    },
    created() {
        this.auth();
        console.log(111, this.$route);
    },
    methods: {
        auth(marketplace) {
            let query = location.search;
            if (!query) {
                query = this.$route.fullPath.replace("/auth/amazon", "");
            }

            this.api
                .get(`amazon/bind${query}`)
                .then(() => {
                    this.result = {
                        status: "success",
                        title: "授权成功",
                        subTitle: "账号授权成功,请返回系统查看。",
                    };
                })
                .catch((data) => {
                    this.result = {
                        status: "error",
                        title: "授权失败",
                        subTitle: `账号授权失败：${data.desc}`,
                    };
                });
        },
    },
};
</script>
<style scoped lang="less">
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>