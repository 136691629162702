<template>
    <a-upload v-bind="$props" :file-list="fileArr" class="file-upload" :custom-request="request" :remove="onRemove"
              @preview="preview">
        <slot>
            <a-icon type="upload" v-if="files.length<maxLength"/>
        </slot>
    </a-upload>
</template>
<script>
import {Upload} from "ant-design-vue";
import {api as viewerApi} from "v-viewer"
import "viewerjs/dist/viewer.css";
import store from "../store";

export default {
    extends: Upload,
    name: "FileUpload",
    model: {
        prop: "files",
        event: "filesChange",
    },
    props: {
        ...Upload.props,
        maxLength: {
            type: Number,
            default: 5,
        },
        files: {
            type: Array | String,
            default: function () {
                return [];
            },
        },
    },
    computed: {
        fileArr() {
            let files = this.files
            let arr = [];
            if (files instanceof Array) {
                for (let item of files) {
                    arr.push({
                        url: item,
                        name: item,
                        uid: item,
                        status: "done",
                    });
                }
            } else if (typeof files == "string") {
                arr.push({
                    url: files,
                    name: files,
                    uid: files,
                    status: "done",
                })
            }
            return arr;
        },
    },
    methods: {
        request(options) {
            let that = this;
            store.dispatch("upload_file", options).then((file) => {
                that.files.push(file.url);
                that.$emit('filesChange', this.files)
            });
        },
        preview(file) {
            viewerApi({
                options: {
                    toolbar: true,
                    initialViewIndex: this.files.indexOf(file.url),
                },
                images: this.files,
            });
        },
        async onRemove(file) {
            let that = this;
            this.$confirm({
                title: "是否确认删除该文件？",
                content: `文件名:${file.name}`,
                okType: "danger",
                onOk() {
                    let index = that.fileArr.indexOf(file)
                    that.files.splice(index, 1);
                    that.$emit('filesChange', that.files)
                },
            });
        },
    }
};
</script>
<style scoped lang="less">
.file-upload {
    /deep/ .ant-upload {
        width: 48px;
        height: 48px;
    }

    /deep/ .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 2px;
    }

    /deep/ .ant-upload-list-picture-card-container {
        width: unset;
        height: unset;
        margin: unset;
    }
}
</style>