<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="名称" prop="name">
                    <a-input v-model="form.name" />
                </a-form-model-item>
                <a-form-model-item label="平台" prop="platform">
                    <a-select v-model="form.platform">
                        <a-select-option v-for="(item,index) in platforms" :key="index" :value="index">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="仓库" prop="warehouseId">
                    <a-select v-model="form.warehouseId">
                        <a-select-option v-for="(item,index) in warehouse" :key="index" :value="item.id">
                            {{item.title}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="form.username" />
                </a-form-model-item>
                <a-form-model-item label="密码" prop="password">
                    <a-input-password v-model="form.password" />
                </a-form-model-item>
                <a-form-model-item label="appKey" prop="appKey">
                    <a-input v-model="form.appKey" />
                </a-form-model-item>
                <a-form-model-item label="appSecret" prop="appSecret">
                    <a-input-password v-model="form.appSecret" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            warehouse: [],
            form: {
                name: "",
                platform: 0,
                username: "",
                password: "",
                appKey: "",
                appSecret: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称必填",
                    },
                ],
                platform: [
                    {
                        required: true,
                        message: "平台必选",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "账号必填",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "密码必填",
                    },
                ],
                appKey: [
                    {
                        required: true,
                        message: "appKey必填",
                    },
                ],
                appSecret: [
                    {
                        required: true,
                        message: "appSecret必填",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["platforms"]),
    },
    methods: {
        edit() {
            this.api.get(`platform/${this.id}`).then((res) => {
                this.form = res.data;
                this.visible = true;
            });
            this.api.get("warehouse/simple").then((res) => {
                this.warehouse = res.data || [];
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("platform", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.$emit("close");
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
