<template>
    <base-page :header="false">
        <a-row :gutter="[5, 15]">
            <a-col span="24">
                <a-card title="订单统计" size="small">
                    <a-steps v-model="current" class="step" @change="stepChange">
                        <a-step title="草稿" status="process">
                            <a-icon slot="icon" type="snippets" />
                            <a-statistic slot="description" :value="statistics['NORMAL']" />
                        </a-step>
                        <a-step title="已提交" status="process">
                            <a-icon slot="icon" type="file-protect" />
                            <a-statistic slot="description" :value="statistics['SUBMIT']" />
                        </a-step>
                        <a-step title="已发货" status="process">
                            <a-icon slot="icon" type="rocket" />
                            <a-statistic slot="description" :value="statistics['SHIPPED']" />
                        </a-step>
                        <a-step title="已拦截" status="error">
                            <a-icon slot="icon" type="exception" />
                            <a-statistic slot="description" :value="statistics['INTERCEPT']" />
                        </a-step>
                        <a-step title="已退回" status="wait">
                            <a-icon slot="icon" type="rollback" />
                            <a-statistic slot="description" :value="statistics['GLOBAL_RETURN']" />
                        </a-step>
                    </a-steps>
                </a-card>
            </a-col>
            <a-col span="12">
                <a-card title="账户概览" size="small">
                    <a-row>
                        <a-col span="8">
                            <a-statistic title="余额" :value="user.money" :precision="2">
                                <template slot="prefix">￥</template>
                            </a-statistic>
                        </a-col>
                        <a-col span="8">
                            <a-statistic title="冻结资金" :value="user.freezeMoney" :precision="2"
                                :value-style="{ color: 'red' }">
                                <template slot="prefix">￥</template>
                            </a-statistic>
                        </a-col>
                        <a-col span="8">
                            <a-space align="baseline">
                                <recharge>
                                    <template slot="trigger" slot-scope="scope">
                                        <a-button type="primary" @click="scope.trigger">充值</a-button>
                                    </template>
                                </recharge>
                            </a-space>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
            <a-col span="12">
                <a-card title="常用工具" size="small">
                    <a-space direction="horizontal">
                        <a-tooltip title="国内快递查询">
                            <a-button type="primary">
                                <a href="https://www.kuaidi100.com" target="_blank">快递100</a>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="国际快递查询">
                            <a-button type="primary">
                                <a href="https://www.17track.net" target="_blank">17track</a>
                            </a-button>
                        </a-tooltip>
                        <a-button type="primary">
                            <a href="https://www.nowmsg.com" target="_blank">邮编查询</a>
                        </a-button>
                        <a-button type="primary">
                            <a href="http://tools.amz123.com/hscode.html" target="_blank">海关HS编码</a>
                        </a-button>
                    </a-space>
                </a-card>
            </a-col>
            <a-col span="24">
                <a-col span="24">
                    <a-card title="系统公告" size="small">
                        <a-list size="small" :data-source="notice" class="notice-list">
                            <a-list-item slot="renderItem" slot-scope="item">
                                <a-list-item-meta :title="item.title" :description="item.body" @click="showNotice(item)">
                                    <a-avatar v-if="item.noticeLevel == 0" slot="avatar" shape="square" icon="info-circle"
                                        style="background-color:#1890ff" />
                                    <a-avatar v-else-if="item.noticeLevel == 1" slot="avatar" shape="square"
                                        icon="exclamation-circle" style="background-color:#faad14" />
                                    <a-avatar v-else-if="item.noticeLevel == 2" slot="avatar" shape="square"
                                        icon="close-circle" style="background-color:#f5222d" />
                                </a-list-item-meta>
                            </a-list-item>
                        </a-list>
                    </a-card>
                </a-col>
            </a-col>
        </a-row>
    </base-page>
</template>

<script>
import Recharge from "../../components/Recharge";
export default {
    components: { Recharge },
    data() {
        return {
            current: -1,
            notice: [],
            statistics: {},
            user: {},
        };
    },
    created() {
        this.api.page("notice", 1, 3).then((res) => {
            this.notice = res.data.records || [];
            for (let i = 0; i < this.notice.length; i++) {
                let item = this.notice[i];
                if (item.popup) {
                    setTimeout(() => {
                        this.showNotice(item);
                    }, i * 1000);
                }
            }
        });
        this.api.get("order/statistics").then((res) => {
            this.statistics = res.data;
        });
        this.api.get("wallet").then((res) => {
            this.user = res.data;
        });
    },
    methods: {
        stepChange(current) {
            this.$router.push({
                path: "distribute/order",
                query: { state: current },
            });
        },
        showNotice(item) {
            let arr = [this.$info, this.$warning, this.$error];
            arr[item.noticeLevel]({
                title: item.title,
                content: item.body,
                maskClosable: true,
            });
        },
    },
};
</script>

<style scoped lang="less">
.step {
    padding: 10px;

    /deep/ .ant-steps-item-container:hover * {
        color: #1890ff;
        cursor: pointer;
        user-select: none;
    }
}

.notice-list {
    .ant-list-item-meta {
        cursor: pointer;

        .ant-list-item-meta-content {
            display: grid;
        }

        &:hover /deep/ .ant-list-item-meta-content * {
            color: black;
            font-weight: bold;
        }

        .ant-list-item-meta-description {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}</style>
