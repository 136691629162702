<template>
    <div>
        <a-button size="small" type="primary" @click="add">添加</a-button>
        <a-modal title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="国内运单号" prop="postId">
                    <a-input v-model="form.postId" />
                </a-form-model-item>
                <a-form-model-item label="类型" prop="type">
                    <a-select v-model="form.type" placeholder="请选择类型">
                        <a-select-option :value="0">无头件</a-select-option>
                        <a-select-option :value="1">物流多发</a-select-option>
                        <a-select-option :value="2">国际退货</a-select-option>
                        <a-select-option :value="3">商家发错</a-select-option>
                        <a-select-option :value="4">商品购买</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="所属人" prop="userId">
                    <a-input-search placeholder="输入用户名/手机号/用户标记进行搜索" enter-button @search="searchUser" />
                    <h3 v-if="user.id">找到用户：{{user.username}}</h3>
                </a-form-model-item>
                <a-form-model-item label="货架号" prop="shelves">
                    <a-input v-model="form.shelves" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {},
    data() {
        return {
            visible: false,
            loading: false,
            form: {},
            user: {},
            rules: {
                postId: [
                    {
                        required: true,
                        message: "国内运单号必填",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "类型必选",
                    },
                ],
            },
        };
    },
    methods: {
        add() {
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.post("receive/add", this.form).then(() => {
                        this.$message.success("添加成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        searchUser(text) {
            this.api.get(`user/search?keyword=${text}`).then((res) => {
                this.user = res.data;
                this.form.userId = this.user.id;
            });
        },
    },
};
</script>
