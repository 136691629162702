<template >
    <a-sub-menu v-if="allow_menus.includes(node.meta.sign)" :key="node.meta.sign" v-bind="$props" v-on="$listeners">
        <span slot="title">
            <a-icon v-if="node.meta && node.meta.icon" :type="node.meta.icon" />
            <span>{{ node.meta.title }}</span>
        </span>
        <template v-for="item in node.children">
            <a-menu-item v-if="!item.children && allow_menus.includes(item.meta.sign)" :key="item.meta.sign"
                @click="$router.nav(item)">
                <a-icon v-if="item.meta && item.meta.icon" :type="item.meta.icon" />
                <span>{{ item.meta.title }}</span>
            </a-menu-item>
            <sub-menu v-else :key="item.meta.sign" :node="item" />
        </template>
    </a-sub-menu>
</template>

<script>
import { Menu } from "ant-design-vue";
import { mapGetters } from "vuex";
export default {
    name: "SubMenu",
    isSubMenu: true,
    props: {
        ...Menu.SubMenu.props,
        node: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters(['allow_menus'])
    }
};
</script>