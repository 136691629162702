<template>
    <div class="container">
        <a-card hoverable class="login-form" :title="ENV.VUE_APP_TITLE + ' - ' + type">
            <a-form-model ref="loginForm" :model="loginForm" :rules="rules" v-if="type == '登录'">
                <a-form-model-item prop="phone">
                    <a-input v-model="loginForm.phone" placeholder="请输入手机号" allowClear :maxLength="11">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item prop="code" v-if="loginForm.type == 'code'">
                    <a-input-search v-model="loginForm.code" placeholder="请输入验证码" allow-clear
                        @search="sendCode('login', loginForm.phone)" :maxLength="18">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                        <a-button slot="enterButton" type="primary" :loading="smsLoading">{{ sendSmsBtn }}</a-button>
                    </a-input-search>
                    <template #extra>
                        <a-button type="link" @click="() => loginForm.type = 'pwd'" style="float:right">切换密码登录
                        </a-button>
                    </template>
                </a-form-model-item>
                <a-form-model-item prop="password" v-else>
                    <a-input-password v-model="loginForm.password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                    <template #extra>
                        <a-button type="link" @click="() => loginForm.type = 'code'" style="float:right">切换短信登录
                        </a-button>
                    </template>
                </a-form-model-item>
                <a-form-model-item style="margin-bottom:0;">
                    <a-button type="primary" html-type="submit" block @click="submit">登录</a-button>
                </a-form-model-item>
            </a-form-model>
            <a-form-model ref="regForm" :model="regForm" :rules="rules" v-if="type == '注册'">
                <a-form-model-item prop="phone">
                    <a-input v-model="regForm.phone" placeholder="请输入手机号" allowClear>
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item prop="password">
                    <a-input-password v-model="regForm.password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="repeatPwd">
                    <a-input-password v-model="regForm.repeatPwd" placeholder="请再次输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="code">
                    <a-input-search v-model="regForm.code" placeholder="请输入验证码" allow-clear
                        @search="sendCode('register', regForm.phone)">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                        <a-button slot="enterButton" type="primary" :loading="smsLoading">{{ sendSmsBtn }}</a-button>
                    </a-input-search>
                </a-form-model-item>
                <a-form-model-item style="margin-bottom:0;">
                    <a-button type="primary" html-type="submit" block @click="regSubmit">注册</a-button>
                </a-form-model-item>
            </a-form-model>
            <a-form-model ref="resetPwdForm" :model="resetPwdForm" :rules="rules" v-if="type == '忘记密码'">
                <a-form-model-item prop="phone">
                    <a-input v-model="resetPwdForm.phone" placeholder="请输入手机号" allowClear>
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item prop="password">
                    <a-input-password v-model="resetPwdForm.password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="repeatPwd">
                    <a-input-password v-model="resetPwdForm.repeatPwd" placeholder="请再次输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="code">
                    <a-input-search v-model="resetPwdForm.code" placeholder="请输入验证码" allow-clear
                        @search="sendCode('forgot', resetPwdForm.phone)">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                        <a-button slot="enterButton" type="primary" :loading="smsLoading">{{ sendSmsBtn }}</a-button>
                    </a-input-search>
                </a-form-model-item>
                <a-form-model-item style="margin-bottom:0;">
                    <a-button type="primary" html-type="submit" block @click="resetPwdSubmit">找回密码</a-button>
                </a-form-model-item>
            </a-form-model>
            <template slot="actions">
                <template v-if="type == '登录'">
                    <a @click="type = '忘记密码'">忘记密码</a>
                    <a @click="type = '注册'">去注册</a>
                </template>
                <template v-else-if="type == '注册'">
                    <a @click="type = '登录'">去登录</a>
                </template>
                <template v-else>
                    <a @click="type = '登录'">去登录</a>
                    <a @click="type = '注册'">去注册</a>
                </template>
            </template>
        </a-card>
        <div class="copyright">
            <p>
                <a href="//beian.miit.gov.cn" target="_blank">{{ ENV.VUE_APP_COPYRIGHT }}</a>
            </p>
            <p>© Copyright <a href="/">{{ ENV.VUE_APP_COMPANY }}</a>. All Rights Reserved</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        let that = this;
        return {
            type: "登录",
            sendSmsBtn: "获取验证码",
            smsLoading: false,
            loginForm: {
                phone: "",
                password: "",
                code: "",
                type: "pwd",
            },
            regForm: {
                phone: "",
                password: "",
                code: "",
                repeatPwd: "",
            },
            resetPwdForm: {
                phone: "",
                password: "",
                code: "",
                repeatPwd: "",
            },
            rules: {
                phone: [
                    {
                        required: true,
                        message: "账号必填",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "密码必填",
                    },
                    {
                        trigger: "blur",
                        min: 6,
                        max: 16,
                        message: "长度在 6 到 16 个字符",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "验证码必填",
                    },
                ],
                repeatPwd: [
                    {
                        required: true,
                        message: "密码必填",
                    },
                    {
                        min: 6,
                        max: 16,
                        message: "长度在 6 到 16 个字符",
                    },
                    {
                        validator(_rule, value, callback) {
                            let pwd =
                                that[
                                    that.type == "注册"
                                        ? "regForm"
                                        : "resetPwdForm"
                                ].password;
                            if (value !== pwd) {
                                callback(new Error("两次输入密码不一致!"));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions(["login", "register", "resetPwd", "smsCode"]),
        submit() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.login(this.loginForm);
                }
            });
        },
        regSubmit() {
            this.$refs.regForm.validate(async (valid) => {
                if (valid) {
                    await this.register(this.regForm);
                    this.type = "登录";
                    this.regForm = {
                        phone: "",
                        password: "",
                        code: "",
                        repeatPwd: "",
                    };
                }
            });
        },
        resetPwdSubmit() {
            this.$refs.resetPwdForm.validate(async (valid) => {
                if (valid) {
                    await this.resetPwd(this.resetPwdForm);
                    this.type = "登录";
                    this.resetPwdForm = {
                        phone: "",
                        password: "",
                        code: "",
                        repeatPwd: "",
                    };
                }
            });
        },
        async sendCode(type, phone) {
            await this.smsCode({
                mobile: phone,
                type: type,
            });
            let delay = 60;
            let sendSmsBtn = this.sendSmsBtn;
            this.smsLoading = true;
            this.sendSmsBtn = `${delay}s`;
            let interval = setInterval(() => {
                if (delay > 1) {
                    delay--;
                    this.sendSmsBtn = `${delay}s`;
                } else {
                    clearInterval(interval);
                    this.smsLoading = false;
                    this.sendSmsBtn = sendSmsBtn;
                }
            }, 1000);
        },
        repeatPwdValidator(rule, value, callback) {
            callback();
        },
    },
};
</script>

<style lang="less" scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background: url("/img/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form {
    width: 25%;
    border-radius: 5px;
    margin-top: -5%;

    /deep/ .ant-card-actions {
        background: unset !important;
        border-top: unset !important;
    }

    /deep/ a {
        color: #1890ff !important;
    }
}

.copyright {
    color: white;
    margin-top: 10px;
    text-align: center;
    background: #3030305c;
    padding: 10px;
    border-radius: 4px;

    p {
        margin-bottom: 2px;
    }
}</style>
