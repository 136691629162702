export default {
    state: {
        // 当前已打开页面
        activeTab: '',
        openTabs: [],
        menus: [],
        work_server_tags: [

            {
                title: '产品破损',
                color: 'orange'
            },
            {
                title: '产品尺寸错误',
                color: 'orange'
            },
            {
                title: '产品与图片不符',
                color: 'orange'
            },
            {
                title: '产品数量错误',
                color: 'orange'
            },
            {
                title: '未填写国内单号',
                color: 'pink'
            },
            {
                title: '泡重',
                color: 'pink'
            },
            {
                title: '拦截改渠道',
                color: 'orange'
            },
            {
                title: '拦截改地址',
                color: 'orange'
            },
            {
                title: '拦截退回',
                color: 'orange'
            },
            {
                title: '拦截退回仓库重发',
                color: 'orange'
            },
            {
                title: '仓库未收到',
                color: 'pink'
            },
            {
                title: '国际件退回到仓库',
                color: 'pink'
            },
            {
                title: '拦截待处理',
                color: '#f50'
            },
            {
                title: '未更新',
                color: 'purple'
            },
            {
                title: '重派',
                color: 'blue'
            },
            {
                title: '查询未收到',
                color: 'cyan'
            },
            {
                title: '国外派送失败',
                color: 'pink'
            },
            {
                title: '已充值正常发货',
                color: 'green'
            },
            {
                title: '余额不足无法发货请充值',
                color: 'red'
            },
            {
                title: '国内找件',
                color: 'pink'
            },
            {
                title: '转库存',
                color: 'pink'
            },
            {
                title: '仓库销毁处理',
                color: 'pink'
            },
            {
                title: '其他',
                color: '#108ee9'
            },
            {
                title: '投诉',
                color: 'purple'
            },
        ]
    },
    getters: {
        menus: state => state.menus,
        openTabs: state => state.openTabs,
        work_server_tags: state => state.work_server_tags,
        menuTree(state) {
            let copy = arr => {
                let nodes = []
                arr.forEach(item => {
                    let node = { title: item.meta.title, key: item.meta.sign }
                    if (item.children && item.children.length > 0) {
                        node.children = copy(item.children)
                    }
                    nodes.push(node)
                })
                return nodes;
            }
            return copy(state.menus)
        }
    },
    mutations: {
        pushOpenTabs(state, tab) {
            if (tab.matched.length > 1 && !state.openTabs.find(i => i.meta == tab.meta)) {
                state.openTabs.push({
                    name: tab.name,
                    path: tab.path,
                    meta: tab.meta,
                    component: tab.matched.at(-1).components.default
                });
                state.activeTab = tab.meta.sign
            }
        },
        setActiveTab(state, value) {
            state.activeTab = value
        },
        setMenus(state, value) {
            state.menus = value
        }
    },
    actions: {
        async pushOpenTabs({ commit }, tab) {
            commit('pushOpenTabs', tab);
        }
    }
}