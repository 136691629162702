<template>
    <base-page>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add></add>
            <a-popconfirm title="是否确定全部删除？" @confirm="del(record)">
                <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
        </a-space>
        <a-table bordered size="small" :rowKey="x => x.id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <template slot="noticeLevel" slot-scope="text, record">
                <a-tag v-if="record.noticeLevel==0" color="cyan">一般</a-tag>
                <a-tag v-else-if="record.noticeLevel==1" color="orange">警告</a-tag>
                <a-tag v-else-if="record.noticeLevel==2" color="red">危险</a-tag>
            </template>
            <template slot="popup" slot-scope="text, record">
                <a-checkbox v-model="record.popup" disabled></a-checkbox>
            </template>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <edit :id="record.id"></edit>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import add from "./Add.vue";
import edit from "./Edit.vue";
import { mapGetters } from "vuex";
export default {
    components: { add, edit },
    data() {
        return {
            data: [],
            columns: [
                {
                    title: "名称",
                    dataIndex: "title",
                },
                {
                    title: "等级",
                    dataIndex: "noticeLevel",
                    scopedSlots: {
                        customRender: "noticeLevel",
                    },
                },
                {
                    title: "自动弹出",
                    dataIndex: "popup",
                    scopedSlots: {
                        customRender: "popup",
                    },
                },
                {
                    title: "用户名",
                    dataIndex: "username",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
        };
    },
    computed: {
        ...mapGetters(["platforms"]),
    },
    created() {
        this.refresh();
    },
    methods: {
        del(record) {
            this.api.delete(`work_server/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh() {
            this.api
                .page(
                    "notice",
                    this.pagination.current,
                    this.pagination.pageSize
                )
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
    },
};
</script>
