<template>
    <div class="container">
        <div class="page-header" v-if="header">
            <slot name="header">
                <a-page-header @back="$router.go(-1)" :title="$route.meta.title" :breadcrumb="breadcrumb">
                    <template slot="extra">
                        <slot name="header-extra"></slot>
                    </template>
                    <template slot="footer">
                        <slot name="header-footer"></slot>
                    </template>
                </a-page-header>
            </slot>
        </div>
        <div class="content">
            <slot>
                <h1>{{ $route.name }}页面测试内容</h1>
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BasePage',
    props: {
        header: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        };
    },
    computed: {
        breadcrumb() {
            return {
                props: {
                    routes: this.$route.matched.concat().map(route => {
                        return {
                            path: route.path,
                            breadcrumbName: route.meta.title || '🏠'
                        }
                    }),
                    itemRender: function ({ route, params, routes, paths }) {
                        return routes.indexOf(route) === routes.length - 1 ? <span>{route.breadcrumbName}</span> : <router-link to={{ path: route.path || '/', params }}>{route.breadcrumbName}</router-link>
                    }
                }
            }
        }
    },
    methods: {

    }
};
</script>

<style scoped lang="less">
.container {
    display: flex;
    flex-direction: column;

    &:hover {
        border-color: #00000017;
        box-shadow: 0 2px 8px #00000017 !important;
    }

    .page-header {
        background: white;
        border-bottom: 1px solid #00000017;

        .ant-page-header {
            margin-bottom: 0;
            border: unset;
        }
    }

    .content {
        background-color: white;
        border: unset;
        padding: 24px;
        border-radius: 0;
        flex: 1;
    }
}
</style>