<template>
    <base-page>
        <template slot="header-extra">
            <a-input-search placeholder="输入关键词搜索" enter-button @search="search" />
        </template>
        <a-list :grid="{ gutter: 16, column: 6 }" :data-source="list" :pagination="pagination">
            <a-list-item slot="renderItem" slot-scope="item, index" style="margin-bottom:16px;">
                <a-card hoverable :body-style="{ padding: '12px 5px' }">
                    <img slot="cover" :src="item.images[0]" />
                    <a-card-meta class="card-meta">
                        <h1 slot="title" style="color:red">￥{{ item.price }}</h1>
                        <h4 slot="description">{{ item.title }}</h4>
                    </a-card-meta>
                </a-card>
            </a-list-item>
        </a-list>
    </base-page>
</template>
<script>
let arr = [];
for (let i = 0; i < 24; i++) {
    arr.push({
        title: "京东京造 3A抑菌抗发黄T恤男40S新疆棉t恤男夏短袖打底T 深灰L",
        price: 238.99,
        images: [
            "https://img14.360buyimg.com/n0/jfs/t1/146243/9/21926/112689/61daed07E91df42b8/7daac369c9cdd8e1.jpg",
        ],
    });
}
export default {
    data() {
        return {
            list: [...arr],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 24,
                size: "small",
                showQuickJumper: true,
            },
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        search(text) {
            this.$message.info(`搜索关键词:${text}`);
        },
        refresh() {
            this.api
                .page(
                    "notice",
                    this.pagination.current,
                    this.pagination.pageSize
                )
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
    },
};
</script>
<style scoped lang="less" >
.ant-list-pagination {
    text-align: center;
}
</style>
