<template>
    <base-page>
        <a-form-model slot="header-extra" layout="inline">
            <a-form-model-item label="时间范围">
                <a-range-picker v-model="form.timeRange" />
            </a-form-model-item>
            <a-form-model-item label="状态">
                <a-select v-model="form.state" placeholder="请选择工单状态" style="width:160px">
                    <a-select-option :value="0">处理中</a-select-option>
                    <a-select-option :value="1">已完成</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="待处理方">
                <a-select v-model="form.userReply" placeholder="请选择待处理方" style="width:160px">
                    <a-select-option :value="0">平台</a-select-option>
                    <a-select-option :value="1">用户</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item>
                <a-input-search placeholder="输入订单号搜索" enter-button @search="search(true)" />
            </a-form-model-item>
        </a-form-model>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add></add>
        </a-space>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" @change="change">
            <span slot="tag" slot-scope="text, record">
                <span v-for="(v, i) in record.tag" :key="i">
                    <a-tag v-if="v !== ''" :color="work_server_tags[v].color">{{ work_server_tags[v].title }}</a-tag>
                </span>
            </span>
            <span slot="state" slot-scope="text, record">
                <a-icon v-if="record.state" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
                <a-icon v-else type="close-circle" theme="twoTone" two-tone-color="#eb2f96" />
            </span>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <edit :id="record.id"></edit>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import add from "./Add.vue";
import edit from "./Edit.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        add,
        edit,
    },
    data() {
        return {
            data: [],
            form: {},
            columns: [
                {
                    title: "国际单号",
                    dataIndex: "trackingNumber",
                },
                {
                    title: "国内单号",
                    dataIndex: "postId",
                },
                {
                    title: "物流平台",
                    dataIndex: "platformName",
                },
                {
                    title: "国家",
                    dataIndex: "country",
                },
                {
                    title: "渠道",
                    dataIndex: "methodCode",
                },
                {
                    title: "标签",
                    dataIndex: "tag",
                    scopedSlots: {
                        customRender: "tag",
                    },
                },
                {
                    title: "状态",
                    dataIndex: "state",
                    scopedSlots: {
                        customRender: "state",
                    },
                },
                {
                    title: "创建人",
                    dataIndex: "username",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
        };
    },
    computed: {
        ...mapGetters(["platforms", "work_server_tags"]),
    },
    created() {
        this.order_state = parseInt(this.$route.query.state || -1);
        this.refresh(true);
    },
    methods: {
        del(record) {
            this.api.delete(`work_server/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("work_server/search", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.data.forEach((item) => {
                        item.tag = JSON.parse(item.tag || "") || [];
                    });
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        search(text) {
            this.form.keyword = text;
            this.refresh();
        },
    },
};
</script>
<style scoped>
.ant-table-tbody .ant-table-row {
    height: 20px;
}
</style>
