<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit"
            @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{ span: 4 }"
                :wrapper-col="{ span: 18, offset: 2 }">
                <a-form-model-item label="名称" prop="name">
                    <a-input v-model="form.name" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        shop: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                name: ""
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称必填",
                    },
                ]
            }
        };
    },
    methods: {
        edit() {
            this.form = this.shop;
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("shop", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.$emit("close");
                        this.form = {};
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.form = {};
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
