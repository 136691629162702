<template>
    <div>
        <a-button size="small" type="danger" @click="edit">快速添加</a-button>
        <a-modal title="快速添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit"
            @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{ span: 4 }"
                :wrapper-col="{ span: 18, offset: 2 }">
                <a-form-model-item label="订单号" prop="orderId">
                    <a-input v-model="form.orderId" />
                </a-form-model-item>
                <a-form-model-item label="店铺" prop="shopId">
                    <v-select v-model="form.shopId" :reduce="option => option.id" :options="shops" placeholder="请选择店铺"
                        style="display: block;" label="name">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {

    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                orderId: "",
                shopId: "",
            },
            shops: [],
            rules: {
                orderId: [
                    {
                        required: true,
                        message: "订单号必填",
                    },
                ],
                shopId: [
                    {
                        required: true,
                        message: "店铺必选",
                    },
                ],
            }
        };
    },
    methods: {
        edit() {
            if (!this.shops || this.shops.length == 0) {
                this.api.get("shop/simple").then((res) => {
                    this.shops = res.data;
                });
            }
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.get("order/quickAdd", new URLSearchParams(Object.entries(this.form))).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.$emit("close");
                        this.form = {};
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.form = {};
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
