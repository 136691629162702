<template >
    <img v-if="url" :src="url" :style="{width:width,height:height}" :title="title" :alt="alt" class="preview-image" @click="preview">
</template>
<script>
import { api as viewerApi } from "v-viewer";
import "viewerjs/dist/viewer.css";
export default {
    name: "PreviewImage",
    props: {
        src: {
            type: [Array, String],
            require: true,
        },
        title: { default: "" },
        alt: { default: "" },
        width: { default: "50px" },
        height: { default: "50px" },
    },
    data() {
        return {};
    },
    computed: {
        url() {
            let arr = this.src;
            return arr instanceof Array ? arr[0] : this.src;
        },
    },
    methods: {
        preview() {
            viewerApi({
                options: {
                    toolbar: true,
                },
                images: this.src,
            });
        },
    },
};
</script>

<style scoped>
.preview-image {
    cursor: pointer;
    border: 1px #bbbbbb solid;
    box-shadow: 0px 0px 4px 1px #707070;
}
</style>
