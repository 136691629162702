import api from '../../utils/api';

export default {
    state: {
        platforms: [],
        country: [],
        order_status: []
    },
    getters: {
        platforms: state => state.platforms,
        country: state => state.country,
        order_status: state => state.order_status
    },
    mutations: {
        platforms(state, value) {
            state.platforms = value;
        },
        country(state, value) {
            state.country = value;
        },
        order_status(state, value) {
            state.order_status = value;
        }
    },
    actions: {
        async platforms({ commit }) {
            commit('platforms', await (await api.get('dispatch/platforms')).data);
        },
        async country({ commit }) {
            commit('country', await (await api.get('dispatch/country')).data);
        },
        async order_status({ commit }) {
            commit('order_status', await (await api.get('order/status')).data);
        },
    }
}