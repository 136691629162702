<template>
    <div>
        <div @click="edit">
            <slot>
                <a-button size="small">查看</a-button>
            </slot>
        </div>
        <a-modal title="查看认领" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="运单号">
                    {{receive.postId}}
                </a-form-model-item>
                <a-card title="产品" size="small">
                    <a-table bordered size="small" :columns="columns" :data-source="receive.seller" :pagination="false" :rowKey="record =>receive.seller.indexOf(record)">
                        <template slot="action" slot-scope="text, record">
                            <a-space size="small">
                                <a-popconfirm title="确认认领信息是否正确？" @confirm="submit(record)">
                                    <a-button size="small" type='primary'>同意</a-button>
                                </a-popconfirm>
                            </a-space>
                        </template>
                    </a-table>
                </a-card>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
        userId: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            roles: [],
            receive: {},
            form: {},
            columns: [
                {
                    title: "卖家名称",
                    dataIndex: "name",
                },
                {
                    title: "卖家电话",
                    dataIndex: "phone",
                },
                {
                    title: "卖家地址",
                    dataIndex: "address",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            rules: {
                postId: [
                    {
                        required: true,
                        message: "请输入完整单号",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "卖家联系方式必填",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "卖家地址必填",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "卖家姓名必填",
                    },
                ],
            },
        };
    },
    computed: {
        finish() {
            return this.userId > 0;
        },
    },
    methods: {
        edit() {
            this.api.get(`receive/${this.id}`).then((res) => {
                this.receive = res.data;
                this.visible = true;
            });
        },
        submit(record) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api
                        .post("receive/finish", {
                            id: this.id,
                            userId: record.userId,
                        })
                        .then(() => {
                            this.$message.success("认领成功");
                            this.$refs.form.resetFields();
                            this.visible = false;
                        });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
