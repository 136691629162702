<template>
    <div>
        <a-button size="small" type="primary" @click="edit">批量编辑</a-button>
        <a-modal title="批量编辑" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="用户标签" prop="userTag">
                    <v-select v-model="form.userTag" :options="tags" placeholder="请选择用户标签" style="display:block;">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="扣费比例" prop="discount">
                    <a-input-number v-model="form.discount" :min="0" :max="200" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </a-form-model-item>
                <a-form-model-item label="启用" prop="enable">
                    <a-switch v-model="form.enable" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        selectedRows: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                discount: 100,
                enable: true,
            },
            tags: [],
        };
    },
    methods: {
        async edit() {
            this.tags = (await this.api.get("user/tags")).data;
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api
                        .post("channel/batchEdit", {
                            ...this.form,
                            ids: this.selectedRows,
                        })
                        .then(() => {
                            this.$message.success("批量修改成功");
                            this.$refs.form.resetFields();
                            this.visible = false;
                            this.$emit("close");
                        });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
