<template>
    <div>
        <div @click="add">
            <slot>
                <a-button size="small" type="primary">添加</a-button>
            </slot>
        </div>
        <a-modal destroyOnClose title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="商品选择" v-if="order&&order.goods" prop="asin">
                    <a-select v-model="form.asin" :filter-option="filterOption" show-search @change="goodsChange" placeholder="请选择相应商品">
                        <a-select-option :title="item.name" v-for="(item, index) in order.goods" :key="index" :value="item.ASIN">
                            <preview-image :src="item.images"></preview-image>
                            {{ item.cName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="SKU" prop="sku" required v-if="!order">
                    <a-input v-model="form.sku" />
                </a-form-model-item>
                <a-form-model-item label="产品名称" prop="name" v-if="!order">
                    <a-input v-model="form.name" placeholder="请输入产品名称" />
                </a-form-model-item>
                <a-form-model-item label="货架号" prop="shelves">
                    <a-input v-model="form.shelves" placeholder="请输入货架编号" />
                </a-form-model-item>
                <a-form-model-item label="库存" prop="stock">
                    <a-input-number id="inputNumber" v-model="form.stock" :min="1" :step="1" placeholder="请输入库存数量" style="width:100%" />
                </a-form-model-item>
                <a-form-model-item label="图片" prop="images" v-if="!order">
                    <file-upload accept=".jpg,.png,.jpeg" listType="picture-card" multiple class="file-upload" v-model="form.images">
                        <a-icon type="upload" v-if="!form.images || form.images.length < 5" />
                    </file-upload>
                </a-form-model-item>
                <a-form-model-item label="备注" prop="remark">
                    <a-textarea v-model="form.remark" placeholder="请输入备注" :row="6" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FileUpload from "@/components/FileUpload.vue";
import PreviewImage from "@/components/PreviewImage.vue";
import Vue from "vue";
export default {
    components: { FileUpload, PreviewImage },
    props: {
        order: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters(["user"]),
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                images: [],
            },
            rules: {
                asin: [
                    {
                        required: true,
                        message: "产品必选",
                    },
                ],
                shelves: [
                    {
                        required: true,
                        message: "货架号必填",
                    },
                ],
                stock: [
                    {
                        required: true,
                        message: "库存数量必填",
                    },
                ],
            },
        };
    },
    methods: {
        add() {
            this.form = {
                images: [],
            };
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.post("stock", this.form).then(() => {
                        this.$message.success("添加成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        sku() {},
        goodsChange(asin) {
            let goods = this.order.goods;
            for (let g of goods) {
                if (g.ASIN == asin) {
                    let item = this.form;
                    item.name = g.cName;
                    item.orderId = this.order.id;
                    item.asin = asin;
                    item.postId = (g.postIds || []).join();
                    item.images = g.images || [];
                    Vue.set(this, "form", item);
                }
            }
        },
        filterOption(text, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            );
        },
    },
};
</script>
<style lang="less" scoped>
.file-upload {
    /deep/ .ant-upload {
        width: 48px;
        height: 48px;
    }

    /deep/ .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 2px;
    }

    /deep/ .ant-upload-list-picture-card-container {
        width: unset;
        height: unset;
        margin: unset;
    }
}
</style>