<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="编辑" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="名称" prop="name">
                    <a-input v-model="form.name" />
                </a-form-model-item>
                <a-form-model-item label="平台" prop="platform">
                    <v-select v-model="form.platform" :reduce="option=>option.platform" :options="platforms" placeholder="请选择物流平台" @option:selected="platformChange" style="display:block;" label="name">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="国家" prop="country">
                    <v-select v-model="form.country" :reduce="option=>option.code" :options="country" placeholder="请选择目标国家" @option:selected="refreshMethod" :get-option-label="e=>e.code+' - '+e.name" style="display:block;">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="运输方式" prop="methodCode">
                    <v-select v-model="form.methodCode" :reduce="option=>option.code" :options="methods" placeholder="请选择运输方式" @option:selected="methodChange" :disabled="!form.country" style="display:block;" label="name">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="用户标签" prop="userTag">
                    <v-select v-model="form.userTag" :options="tags" placeholder="请选择用户标签" style="display:block;">
                        <span slot="no-options">没有结果</span>
                    </v-select>
                </a-form-model-item>
                <a-form-model-item label="扣费比例" prop="discount">
                    <a-input-number v-model="form.discount" :min="0" :max="200" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </a-form-model-item>
                <a-form-model-item label="启用" prop="enable">
                    <a-switch v-model="form.enable" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                discount: 100,
                enable: true,
            },
            tags: [],
            methods: [],
            platforms: [],
            platformId: 0,
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称必填",
                    },
                ],
                platform: [
                    {
                        required: true,
                        message: "平台必选",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "国家必选",
                    },
                ],
                method_code: [
                    {
                        required: true,
                        message: "运输方式必选",
                    },
                ],
                user_tag: [
                    {
                        required: true,
                        message: "用户标签必填",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["country"]),
    },
    methods: {
        async edit() {
            this.form = (await this.api.get(`channel/${this.id}`)).data;
            this.tags = (await this.api.get("user/tags")).data;
            this.platforms = (await this.api.get("platform/simple")).data;
            let p = this.platforms.find(
                (e) => e.platform == this.form.platform
            );
            if (p) {
                this.platformId = p.id;
            }
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("channel", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                        this.$emit("close");
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        platformChange(e) {
            this.platformId = e.id;
            this.refreshMethod();
        },
        methodChange(e) {
            this.form.method_name = e.name;
        },
        refreshMethod() {
            if (this.platformId && this.form.country) {
                this.api
                    .get(
                        `channel/methods?platform=${this.platformId}&country=${this.form.country}`
                    )
                    .then((res) => {
                        this.methods = res.data;
                    });
            }
        },
    },
};
</script>
