import OSS from 'ali-oss';
import { message } from 'ant-design-vue';
import api from '../../utils/api';
import util from '../../utils/util';

export default {
	mutations: {
		SET_STS(state, sts) {
			state.sts = sts;
		},
		CREATE_CLIENT(state) {
			state.client = new OSS({
				...state.sts,
				refreshSTSToken: async () => {
					return await this.dispatch('refreshSTS')
				}
			});
		}
	},
	actions: {
		async refreshSTS({ state, commit }) {
			let res = await api.get('storage/sts');
			commit('SET_STS', res.data)
			return state.sts;
		},
		async upload_file({ state, commit, dispatch, getters }, args) {
			try {
				if (!state.client) {
					await dispatch('refreshSTS')
					commit('CREATE_CLIENT')
				}
				let file = args.file;
				let suffix = util.suffix(file.name);
				let name = `${util.randomString(12)}.${suffix}`;
				let response = await state.client.put(
					`user_${getters.user.id}/${util.format(Date.now(), "Y/M/D")}/${name}`, file, {
						progress(percentage) {
							args.onProgress({ percent: percentage * 100 })
						}
					})
				file.url = response.url;
				args.onSuccess(file)
				return file;
			} catch (error) {
				args.onError(error, '上传错误');
				message.error(`上传错误: ${args.file.name}`)
				throw '文件上传错误';
			}
		}
	}
}
