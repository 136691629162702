<template>
    <div>
        <div @click="add">
            <slot>
                <a-button size="small" type="primary">国内退件</a-button>
            </slot>
        </div>
        <a-modal class="modal-form" title="国内退件" :model="returnInfo" :visible="visible" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form">
                <a-form-model-item label="姓名">
                    <a-input v-model="returnInfo.name" />
                </a-form-model-item>
                <a-form-model-item label="电话">
                    <a-input v-model="returnInfo.phone" />
                </a-form-model-item>
                <a-form-model-item label="省市">
                    <a-input v-model="returnInfo.city" />
                </a-form-model-item>
                <a-form-model-item label="详细地址">
                    <a-input v-model="returnInfo.address" type="textarea" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            form: {},
            returnInfo: {},
        };
    },
    methods: {
        add() {
            this.api.get(`order/${this.id}`).then((res) => {
                this.form = res.data;
                this.returnInfo = this.form.returnInfo =
                    this.form.returnInfo || {};
                this.visible = true;
            });
        },
        submit() {
            this.api.post("order/return", this.form).then(() => {
                this.$message.success("添加成功");
                this.visible = false;
            });
        },
        cancel() {
            this.visible = false;
        },
    },
};
</script>
<style scoped lang="less">
.ant-select {
    min-width: 180px;
}

.ant-form-item {
    margin-right: unset;
}

.file-upload {
    /deep/ .ant-upload {
        width: 48px;
        height: 48px;
    }

    /deep/ .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 2px;
    }

    /deep/ .ant-upload-list-picture-card-container {
        width: unset;
        height: unset;
        margin: unset;
    }
}

.post-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tag {
        margin-bottom: 5px;
        user-select: none;
    }
}
</style>