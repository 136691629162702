<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="编辑" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model v-if="visible" ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="手机号">
                    {{form.phone}}
                </a-form-model-item>
                <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="form.username" />
                </a-form-model-item>
                <a-form-model-item label="昵称" prop="nickname">
                    <a-input v-model="form.nickname" />
                </a-form-model-item>
                <a-form-model-item label="角色" prop="role">
                    <a-select v-model="form.roleId">
                        <a-select-option v-for="(item,index) in roles" :key="index" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="标签" prop="tag">
                    <a-input v-model="form.tag" />
                </a-form-model-item>
                <a-form-model-item label="打包费用" prop="tag">
                    <a-input-number :min="0" :step="0.01" v-model="form.packageFee" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            roles: [],
            form: {
                username: "",
                nickname: "",
                password: "",
                packageFee: 3.5,
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "用户名必填",
                    },
                ],
                nickname: [
                    {
                        required: true,
                        message: "昵称必填",
                    },
                ],
                roleId: [
                    {
                        required: true,
                        message: "角色必选",
                    },
                ],
                packageFee: [
                    {
                        required: true,
                        message: "打包费用必填",
                    },
                ],
            },
        };
    },
    methods: {
        edit() {
            this.api.get(`user/${this.id}`).then((res) => {
                this.form = res.data;
                this.visible = true;
            });
            this.api.get(`role/list`).then((res) => {
                this.roles = res.data;
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("user", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                        this.$emit("close");
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
    },
};
</script>
