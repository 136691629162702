<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal class="modal-form" title="编辑" width="70%" :visible="visible" :confirm-loading="loading"
                 :maskClosable="false" @cancel="cancel" @ok="submit">
            <a-form-model ref="form" :model="form" :rules="rules" label-align="left" layout="inline">
                <a-row type="flex" justify="space-between">
                    <a-form-model-item label="物流平台" prop="platformId">
                        <v-select v-model="form.platformId" :disabled="freeze" :reduce="option=>option.id"
                                  :options="platforms" placeholder="请选择物流平台" @option:selected="refreshMethod"
                                  style="min-width:150px;" label="name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                    <a-form-model-item label="目标国家" prop="country">
                        <v-select v-model="form.country" :disabled="freeze" :reduce="option=>option.code"
                                  :options="country" placeholder="请选择目标国家" @option:selected="refreshMethod"
                                  style="min-width:150px;" :get-option-label="e=>e.code+' - '+e.name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                    <a-form-model-item label="运输方式" prop="methodCode">
                        <v-select v-model="form.methodCode" :disabled="freeze" :reduce="option=>option.code"
                                  :options="methods" placeholder="请选择运输方式" style="min-width:200px;" label="name">
                            <span slot="no-options">没有结果</span>
                        </v-select>
                    </a-form-model-item>
                    <a-form-model-item label="海关编码" prop="tax">
                        <a-input v-model="form.tax" :disabled="freeze"/>
                    </a-form-model-item>
                </a-row>
                <br/>
                <a-card title="收件人" size="small">
                    <a-row type="flex" justify="space-between">
                        <a-form-model-item label="国家">
                            <a-input v-model="form.receiver.country" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="省份">
                            <a-input v-model="form.receiver.province" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="城市">
                            <a-input v-model="form.receiver.city" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="地址1">
                            <a-textarea v-model="form.receiver.street" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="地址2">
                            <a-textarea v-model="form.receiver.addr" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="邮编">
                            <a-input v-model="form.receiver.zip" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="姓名">
                            <a-input v-model="form.receiver.name" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="联系方式">
                            <a-input v-model="form.receiver.phone" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="邮箱">
                            <a-input v-model="form.receiver.email" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="售价" prop="price">
                            <a-input-number :min="0" :step="0.01" v-model="form.price" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="备注">
                            <a-textarea placeholder="请输入备注信息" :rows="2" v-model="form.remark"/>
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <br/>
                <a-card title="费用" size="small" v-role='["SYSTEM", "ADMIN","WAREHOUSE_ADMIN","WAREHOUSE_USER"]'>
                    <a-row type="flex" justify="space-between">
                        <a-form-model-item label="国际物流费">
                            <a-input v-model="form.shipFee" :disabled="freeze"/>
                        </a-form-model-item>
                        <a-form-model-item label="打包类别">
                            <a-input v-model="form.packageType"/>
                        </a-form-model-item>
                        <a-form-model-item label="打包材料">
                            <a-input v-model="form.packageMaterial"/>
                        </a-form-model-item>
                        <a-form-model-item label="打包费用">
                            <a-input-number :min="0" :step="0.01" v-model="form.packageFee"/>
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <br/>
                <a-card title="采购信息" size="small">
                    <a-row type="flex" justify="space-between">
                        <a-form-model-item label="采购订单号">
                            <a-tooltip title="采购单号便于搜索查询填写国内单号">
                                <a-input v-model="form.purchaseInfo.purchase_order_number" :disabled="freeze"
                                         placeholder="采购单号便于搜索查询填写国内单号"/>
                            </a-tooltip>
                        </a-form-model-item>
                        <a-form-model-item label="进价">
                            <a-input-number :min="0" :step="0.01" v-model="form.purchaseInfo.price"/>
                        </a-form-model-item>
                        <a-form-model-item label="数量">
                            <a-input-number :min="0" :step="1" v-model="form.purchaseInfo.number"/>
                        </a-form-model-item>
                        <a-form-model-item label="商品链接">
                            <a-input v-model="form.purchaseInfo.url"/>
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <br/>
                <a-descriptions bordered>
                    <a-descriptions-item label="下单时间">{{ form.createTime }}</a-descriptions-item>
                    <a-descriptions-item label="创建时间">{{ form.createTime }}</a-descriptions-item>
                    <a-descriptions-item label="最后修改">{{ form.updateTime }}</a-descriptions-item>
                    <a-descriptions-item v-if="form.shipAt" label="发货时间">{{ form.shipAt }}</a-descriptions-item>
                    <a-descriptions-item label="用户" v-if="form.attach && form.attach.username">{{
                            form.attach.username
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="联系方式" v-if="form.attach && form.attach.phone">{{
                            form.attach.phone
                        }}
                    </a-descriptions-item>
                </a-descriptions>
                <br/>
                <a-card title="库存选择" size="small" v-if="!freeze">
                    <a-button slot="extra" size="small" type="primary" @click="addStock">添加</a-button>
                    <a-table v-if="this.form.stock&&this.form.stock.length>0" bordered size="small"
                             :columns="stockColumns" :data-source="form.stock" :pagination="false"
                             :rowKey="record => form.stock.indexOf(record)">
                        <template slot="index" slot-scope="text, record">
                            {{ (form.stock || []).indexOf(record) + 1 }}
                        </template>
                        <template slot="stockId" slot-scope="text, record">
                            <a-select :filter-option="filterOption" show-search v-model="record.stockId"
                                      placeholder="请选择相应商品" style="width:100%">
                                <a-select-option :title="item.name" v-for="(item, index) in stockList" :key="index"
                                                 :value="item.id">
                                    <preview-image :src="item.images"></preview-image>
                                    {{ item.sku }} - {{ item.name }} - {{ item.stock }}个
                                </a-select-option>
                            </a-select>
                        </template>
                        <template slot="number" slot-scope="text, record">
                            <a-input-number :min="1" :step="1" v-model="record.number"/>
                        </template>
                        <template slot="action" slot-scope="text, record" v-if="!freeze">
                            <a-space size="small">
                                <a-popconfirm title="是否确定删除？" @confirm="removeStock(record)">
                                    <a-button size="small" type="danger">删除</a-button>
                                </a-popconfirm>
                            </a-space>
                        </template>
                    </a-table>
                </a-card>
                <br/>
                <a-card title="产品" size="small">
                    <a-button slot="extra" v-if="!freeze" size="small" type="primary" @click="addGoods">添加</a-button>
                    <a-table bordered size="small" :columns="columns" :data-source="form.goods" :pagination="false"
                             :rowKey="record => form.goods.indexOf(record)">
                        <template slot="images" slot-scope="text, record">
                            <file-upload accept=".jpg,.png,.jpeg" listType="picture-card" multiple
                                         v-model="record.images"></file-upload>
                        </template>
                        <template slot="IOSS" slot-scope="text, record">
                            <a-input v-model="record.IOSS" placeholder="请输入IOSS" :disabled="freeze"
                                     style="width:70px;"></a-input>
                        </template>
                        <template slot="EName" slot-scope="text, record">
                            <a-input v-model="record.eName" placeholder="请输入英文名称" style="width:70px;"></a-input>
                        </template>
                        <template slot="CName" slot-scope="text, record">
                            <a-input v-model="record.cName" placeholder="请输入中文名称" style="width:70px;"></a-input>
                        </template>
                        <template slot="postIds" slot-scope="text, record">
                            <a-popover title="点击相应订单号查询物流状态（输入后请回车）">
                                <span slot="content" class="post-list">
                                    <a-tag color="cyan" v-for="(item, index) in (record.postIds || [])" :key="index"
                                           closable @close="removePostId($event, record, item)"
                                           @click="clickPostId(item)">
                                        {{ item }}
                                    </a-tag>
                                </span>
                                <a-input placeholder="请输入国内单号" v-model="record.postId"
                                         @pressEnter="addPostId(record)"/>
                            </a-popover>
                        </template>
                        <template slot="quantity" slot-scope="text, record">
                            <a-input-number :min="0" v-model="record.quantity" style="width:70px;"/>
                        </template>
                        <template slot="currencyCode" slot-scope="text, record">
                            <a-input v-model="record.currencyCode" style="width:70px;"></a-input>
                        </template>
                        <template slot="price" slot-scope="text, record">
                            <a-input-number :min="0" :step="0.01" v-model="record.price" style="width:70px;"/>
                        </template>
                        <template slot="unitPrice" slot-scope="text, record">
                            <a-input-number :min="0" :step="0.01" v-model="record.unitPrice"/>
                        </template>
                        <template slot="weight" slot-scope="text, record">
                            <a-input-number :min="0" :step="0.01" v-model="record.weight" style="width:70px;"/>
                        </template>
                        <template slot="remark" slot-scope="text, record">
                            <a-input v-model="record.remark" placeholder="发货注意事项"></a-input>
                        </template>
                        <template slot="action" slot-scope="text, record" v-if="!freeze">
                            <a-space size="small">
                                <a-popconfirm title="是否确定删除？" @confirm="delGoods(record)">
                                    <a-button size="small" type="danger">删除</a-button>
                                </a-popconfirm>
                            </a-space>
                        </template>
                    </a-table>
                </a-card>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
import PreviewImage from "@/components/PreviewImage.vue";
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
    components: {FileUpload, PreviewImage},
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            freeze: false,
            form: {
                receiver: {},
                goods: [],
                purchaseInfo: {},
                stock: [],
            },
            stockList: [],
            methods: [],
            platforms: [],
            rules: {
                platformId: [
                    {
                        required: true,
                        message: "平台必选",
                    },
                ],
            },
            stockColumns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    scopedSlots: {customRender: "index"},
                },
                {
                    title: "库存产品",
                    dataIndex: "stockId",
                    scopedSlots: {customRender: "stockId"},
                },
                {
                    title: "数量",
                    dataIndex: "number",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 80,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["country"]),
        columns() {
            let arr = [
                {
                    title: "图片",
                    key: "images",
                    dataIndex: "images",
                    align: "center",
                    scopedSlots: {customRender: "images"},
                },
                {
                    title: "IOSS",
                    dataIndex: "IOSS",
                    align: "center",
                    scopedSlots: {customRender: "IOSS"},
                },
                {
                    title: "英文名称",
                    dataIndex: "EName",
                    align: "center",
                    scopedSlots: {customRender: "EName"},
                },
                {
                    title: "中文名称",
                    dataIndex: "CName",
                    align: "center",
                    scopedSlots: {customRender: "CName"},
                },
                {
                    title: "国内物流",
                    dataIndex: "postIds",
                    align: "center",
                    scopedSlots: {customRender: "postIds"},
                },
                {
                    title: "数量",
                    dataIndex: "quantity",
                    align: "center",
                    scopedSlots: {
                        customRender: "quantity",
                    },
                },
                {
                    title: "币种",
                    dataIndex: "currencyCode",
                    align: "center",
                    scopedSlots: {
                        customRender: "currencyCode",
                    },
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    align: "center",
                    scopedSlots: {
                        customRender: "price",
                    },
                },
                {
                    title: "报关价格",
                    dataIndex: "unitPrice",
                    align: "center",
                    scopedSlots: {
                        customRender: "unitPrice",
                    },
                },
                {
                    title: "重量（KG）",
                    dataIndex: "weight",
                    align: "center",
                    scopedSlots: {
                        customRender: "weight",
                    },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    align: "center",
                    scopedSlots: {
                        customRender: "remark",
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 80,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ];
            if (this.util.hasRole(["SYSTEM", "ADMIN"])) {
                arr.ins;
                arr.splice(1, 0, {
                    title: "ASIN",
                    dataIndex: "ASIN",
                });
            }
            return arr;
        },
    },
    methods: {
        async edit() {
            this.platforms = (await this.api.get("platform/simple")).data;
            this.stockList = (await this.api.get("stock/simple")).data;
            let info = (await this.api.get(`order/${this.id}`)).data;
            info.receiver = info.receiver || {};
            info.purchaseInfo = info.purchaseInfo || {};
            if (info.platformId == 0) {
                info.platformId = undefined;
            }
            info.goods = info.goods || [];
            info.stock = info.stock || [];
            this.freeze = info.state > 0;
            this.form = info;
            this.refreshMethod();
            this.visible = true;
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("order", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.visible = false;
        },
        addGoods() {
            this.form.goods.push({
                EName: "",
                CName: "",
                quantity: 1,
                currencyCode: "USD",
                price: 0,
                weight: 0,
                unitPrice: 0,
                postIds: [],
            });
        },
        delGoods(record) {
            let goods = this.form.goods;
            let index = goods.indexOf(record);
            goods.splice(index, 1);
        },
        addPostId(record) {
            if (record.postId) {
                record.postIds = record.postIds || [];
                if (record.postIds.indexOf(record.postId) < 0) {
                    record.postIds.push(record.postId);
                    record.postId = "";
                } else {
                    this.$message.warning("当前订单号已存在");
                }
            }
        },
        removePostId(e, record, value) {
            let index = record.postIds.indexOf(value);
            record.postIds.splice(index, 1);
            e.preventDefault();
        },
        clickPostId(value) {
            window.open(`https://www.baidu.com/s?wd=${value}`);
        },
        refreshMethod() {
            if (this.form.platformId && this.form.country) {
                this.form.receiver.country = this.form.country;
                this.api
                    .get(
                        `dispatch/methods/user?platform=${this.form.platformId}&country=${this.form.country}`
                    )
                    .then((res) => {
                        this.methods = res.data;
                    });
            }
        },
        filterOption(text, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            );
        },
        addStock() {
            let arr = this.form.stock;
            arr.push({
                number: 1,
            });
            Vue.set(this.form, "stock", arr);
        },
        removeStock(record) {
            let arr = this.form.stock;
            let index = arr.indexOf(record);
            arr.splice(index, 1);
        },
    },
};
</script>
<style scoped lang="less">
.ant-select {
    width: 180px;
}

.ant-form-item {
    margin-right: unset;
}
</style>