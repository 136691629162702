<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="编辑" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="名称" prop="name">
                    <a-input v-model="form.name" />
                </a-form-model-item>
                <a-form-model-item label="标识" prop="tag">
                    <a-input v-model="form.tag" @change="tagChange" />
                </a-form-model-item>
                <a-form-model-item label="菜单" prop="menus">
                    <a-card>
                        <a-tree checkable check-strictly :tree-data="menuTree" v-model="form.menus" />
                    </a-card>
                </a-form-model-item>
                <a-form-model-item label="权限" prop="permissions">
                    <a-card>
                        <a-checkbox-group v-model="form.permissions" :options="permissions" />
                    </a-card>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {},
            permissions: [
                {
                    label: "订单敏感信息",
                    value: "order:sensitive",
                },
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称必填",
                    },
                ],
                tag: [
                    {
                        required: true,
                        message: "标识必填",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["menuTree"]),
    },
    methods: {
        edit() {
            this.api.get(`role/${this.id}`).then((res) => {
                this.form = res.data;
                this.visible = true;
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.menus = this.form.menus.checked;
                    this.api.put("role", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        tagChange() {
            this.form.tag = (this.form.tag || "").toUpperCase();
        },
    },
};
</script>
