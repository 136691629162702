<template >
    <a-radio-button v-bind="$props" v-on="$listeners" :style="{'width':width,'height':height}" @change="change">
        <img :src="src" :style="{'width':width,'height':height}">
        <a-icon v-if="$parent.value==value" class="checked" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
    </a-radio-button>
</template>
<script>
import { Radio } from 'ant-design-vue';
export default {
    name: 'ImageRadio',
    props: {
        ...Radio.props,
        src: {
            type: String,
            require: true
        },
        isGroup: {
            default: true
        },
        width: {
            default: '130px'
        },
        height: {
            default: 'auto'
        }
    },
    created() {
        console.log(this);
    },
    methods: {
        // ...Radio.methods
        change(e) {
            console.log(11, e);
        }
    },
    watch: {
        $props: function (newVal, oldVal) {
            console.log('new:', newVal, 'old:', oldVal);
        }
    }
};
</script>

<style scoped>
.checked {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 26px;
}

.ant-radio-button-wrapper {
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    padding: unset;
    border: unset;
    line-height: unset;
    background: unset;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: unset;
    border-color: unset;
    background-size: contain;
    background-repeat: no-repeat;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.ant-radio-button-wrapper-disabled {
    background-color: gray;
}
</style>
