<template >
    <div>
        <slot name="trigger" :trigger="show"></slot>
        <a-modal class="modal-form" title="充值" width="70%" :visible="visible" :maskClosable="false" @ok="submit" @cancel="visible=false">
            <a-row>
                <a-col span="12">
                    <a-card>
                        <a-form labelAlign="left" :colon="false" :label-col="{span:5}" :wrapper-col="{span:12,offset:1}">
                            <a-form-item>
                                <h1 slot="label">充值账户：</h1>
                                <h1>{{ user.nickname }}</h1>
                            </a-form-item>
                            <a-form-item>
                                <h1 slot="label">充值金额：</h1>
                                <a-tooltip title="最低充值金额：10元">
                                    <a-input-number size="large" v-model="form.money" :default-value="100" :min="10" :precision="2" style="width:80%"></a-input-number>
                                </a-tooltip>
                            </a-form-item>
                            <a-form-item>
                                <h1 slot="label">支付渠道：</h1>
                                <a-radio-group default-value="ALIPAY" button-style="solid" class="platform" v-model="form.platform">
                                    <image-radio src="/img/alipay.png" value="ALIPAY" />
                                    <image-radio v-if="0" src="/img/wechat.png" value="WECHAT" />
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="recharge">充值</a-button>
                            </a-form-item>
                        </a-form>
                    </a-card>
                </a-col>
                <a-col span="12">
                    <a-card>
                        <div v-if="qrcode" class="qrcode">
                            <qr :text="qrcode" :size="200"></qr>
                            <h3><b>请扫码支付</b></h3>
                        </div>
                        <a-empty v-else description="请在左侧填写信息并点击充值按钮" />
                    </a-card>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
import qr from "vue-qr";
import ImageRadio from "../components/ImageRadio.vue";
import { mapGetters } from "vuex";
export default {
    components: { qr, ImageRadio },
    props: {},
    data() {
        return {
            visible: false,
            current: 0,
            form: {
                platform: "ALIPAY",
                money: 100,
            },
            qrcode: "",
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        show() {
            this.current = 0;
            this.visible = true;
        },
        submit() {
            this.visible = false;
            setTimeout(() => {
                location.href = "/";
            }, 500);
        },
        recharge() {
            this.api
                .get(
                    `wallet/recharge?platform=${this.form.platform}&money=${this.form.money}`
                )
                .then((res) => {
                    this.qrcode = res.data.qrCode;
                    console.log(123, res.data);
                });
        },
    },
};
</script>

<style scoped>
.ant-form-item-label h1 {
    color: #fa8639;
}

.modal-form >>> .ant-form-item-no-colon::after {
    display: none;
}

.qrcode {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}
</style>