import Vue from "vue";
import Router from "vue-router";
import store from '../store';

import RouterPage from '@/components/RouterPage.vue'

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    strict: process.env.NODE_ENV !== 'production',
    routes: [{
        path: "*",
        redirect: "/",
    },
    {
        path: "/",
        component: () => import("../views/Index"),
        children: [{
            path: "/",
            name: 'Home',
            component: () => import("@/views/home/Index"),
            meta: {
                title: '首页',
                sign: "home",
                icon: "home"
            }
        },
        {
            path: "goods",
            name: 'Goods',
            meta: {
                title: '货源中心',
                sign: "goods",
                icon: "gift"
            },
            component: RouterPage,
            redirect: "goods/list",
            children: [
                {
                    name: 'GoodsList',
                    path: "list",
                    component: () => import("../views/goods/list/Index"),
                    meta: {
                        title: "商品列表",
                        sign: "goods-list"
                    }
                },
                {
                    name: 'Stock',
                    path: "stock",
                    component: () => import("../views/goods/stock/Index"),
                    meta: {
                        title: "我的库存",
                        sign: "stock"
                    }
                },
                {
                    name: 'Receive',
                    path: "receive",
                    component: () => import("../views/goods/receive/Index"),
                    meta: {
                        title: "物流认领",
                        sign: "receive"
                    }
                }
            ]
        },
        {
            path: "distribute",
            name: 'Distribute',
            meta: {
                title: "派发管理",
                sign: "distribute",
                icon: "profile"
            },
            component: RouterPage,
            redirect: "distribute/list",
            children: [
                {
                    path: "order",
                    name: 'Order',
                    component: () => import("@/views/distribute/order/Index"),
                    meta: {
                        title: "订单列表",
                        sign: "order"
                    }
                },
                {
                    path: "shop",
                    name: 'Shop',
                    component: () => import("@/views/distribute/shop/Index"),
                    meta: {
                        title: "店铺列表",
                        sign: "shop"
                    }
                }
            ]
        },
        {
            path: "logistics",
            name: 'Logistics',
            meta: {
                title: "物流管理",
                sign: "logistics",
                icon: "car"
            },
            component: RouterPage,
            redirect: "logistics/platform",
            children: [
                {
                    path: "platform",
                    name: 'Platform',
                    component: () => import("../views/logistics/platform/Index"),
                    meta: {
                        title: "平台管理",
                        sign: "platform"
                    }
                },
                {
                    path: "warehouse",
                    name: 'Wlatform',
                    component: () => import("../views/logistics/warehouse/Index"),
                    meta: {
                        title: "仓库管理",
                        sign: "warehouse"
                    }
                },
                {
                    path: "fast-print",
                    name: 'FastPrint',
                    component: () => import("../views/logistics/fast-print/Index"),
                    meta: {
                        title: "极速打单",
                        sign: "fast-print"
                    }
                },
                {
                    path: "channel",
                    name: 'Channel',
                    component: () => import("../views/logistics/channel/Index"),
                    meta: {
                        title: "渠道分配",
                        sign: "channel"
                    }
                }
            ]
        },
        {
            path: "trade",
            name: 'Trade',
            meta: {
                title: "交易管理",
                sign: "trade",
                icon: "transaction"
            },
            component: RouterPage,
            redirect: "trade/recharge",
            children: [
                {
                    path: "god",
                    name: 'God',
                    component: () => import("../views/trade/god/Index"),
                    meta: {
                        title: "上帝模式",
                        sign: "god"
                    }
                },
                {
                    path: "recharge",
                    name: 'Recharge',
                    component: () => import("../views/trade/recharge/Index"),
                    meta: {
                        title: "充值记录",
                        sign: "recharge"
                    }
                },
                {
                    path: "pay",
                    name: 'Pay',
                    component: () => import("../views/trade/pay/Index"),
                    meta: {
                        title: "消费记录",
                        sign: "pay"
                    }
                }
            ]
        },
        {
            path: "statistics",
            name: 'Statistics',
            component: () => import("../views/statistics/Index"),
            meta: {
                title: "报表中心",
                sign: "statistics",
                icon: "pie-chart"
            }
        },
        {
            path: "work-server",
            name: 'WorkServer',
            component: () => import("../views/work-server/Index"),
            meta: {
                title: "工单管理",
                sign: "work-server",
                icon: "container"
            }
        },
        {
            path: "authorize",
            name: 'Authorize',
            meta: {
                title: "权限管理",
                sign: "authorize",
                icon: "insurance"
            },
            component: RouterPage,
            redirect: "authorize/user",
            children: [
                {
                    path: "user-profile",
                    name: 'UserProfile',
                    component: () => import("../views/authorize/user/UserProfile"),
                    meta: {
                        title: "账户设置",
                        sign: "user-profile",
                    }
                },
                {
                    path: "user",
                    name: 'User',
                    component: () => import("../views/authorize/user/Index"),
                    meta: {
                        title: "用户管理",
                        sign: "user",
                    }
                },
                {
                    path: "role",
                    name: 'Role',
                    component: () => import("../views/authorize/role/Index"),
                    meta: {
                        title: "角色管理",
                        sign: "role",
                    }
                }
            ]
        },
        {
            path: "setting",
            name: 'Setting',
            meta: {
                title: "系统设置",
                sign: "setting",
                icon: "setting"
            },
            component: RouterPage,
            redirect: "setting/notice",
            children: [
                {
                    path: "notice",
                    name: 'Notice',
                    component: () => import("../views/notice/Index"),
                    meta: {
                        title: "系统公告",
                        sign: "notice"
                    }
                }
            ]
        }]
    },
    {
        path: "/login",
        name: "登录",
        component: () => import("../views/Login"),
    },
    {
        path: "/auth/amazon",
        name: 'AmazonAuth',
        component: () => import("@/views/common/AmazonAuth"),
        meta: {
            title: "亚马逊授权",
            sign: "amazon-auth"
        }
    }],
});

router.nav = route => {
    if (route.redirect) {
        window.open(route.redirect)
    } else {
        router.push(route)
    }
}

/**
 * 前置守卫
 */
router.beforeEach((to, from, next) => {
    console.log("%c路由更新：", "color:red;", from, " >>> ", to);
    next();
    store.dispatch('pushOpenTabs', to)
    router.layers = to;
})

router.onReady(() => {
    store.commit('setMenus', router.options.routes[1].children)
})

/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

export default router;