<template>
    <a-layout>
        <a-layout-sider v-model="collapsed" collapsible class="sider">
            <a-space class="brand" @click="refresh">
                <img src="/logo.png" class="pointer" />
                <span v-if="!collapsed" class="brand-title pointer">{{ ENV.VUE_APP_TITLE }}</span>
            </a-space>
            <a-menu class="sider-menu" theme="dark" mode="inline" :selectedKeys="[$route.meta.sign]"
                :defaultOpenKeys="openKeys">
                <template v-for="node in menus">
                    <sub-menu v-if="node.children" :node="node" :key="node.meta.sign" />
                    <a-menu-item v-else-if="allow_menus.includes(node.meta.sign)" :key="node.meta.sign"
                        @click="$router.nav(node)">
                        <a-icon v-if="node.meta && node.meta.icon" :type="node.meta.icon" />
                        <span>{{ node.meta.title }}</span>
                    </a-menu-item>
                </template>
            </a-menu>
        </a-layout-sider>
        <a-layout v-if="user">
            <a-layout-header class="header">
                <a-row type="flex" justify="space-between" align="middle" style="height: 100%">
                    <a-col>
                        <a-space size="middle">
                            <a-icon type="reload" class="pointer" @click="reload" />
                            <a-breadcrumb v-if="0" v-bind="breadcrumb"></a-breadcrumb>
                        </a-space>
                    </a-col>
                    <a-col>
                        <a-row type="flex" justify="center" :gutter="16">
                            <a-col class="user-avatar">
                                <a-dropdown>
                                    <a class="ant-dropdown-link">
                                        {{ user ? user.nickname : "当前用户" }}
                                        <a-icon type="down" />
                                    </a>
                                    <a-menu slot="overlay" size="small">
                                        <a-menu-item>
                                            <span @click="$router.push('/authorize/user-profile')">账户设置</span>
                                        </a-menu-item>
                                        <a-menu-divider />
                                        <a-menu-item>
                                            <div style="color: red" @click="exit">
                                                <a-icon type="poweroff" />
                                                <span>退出</span>
                                            </div>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-layout-header>
            <a-tabs v-if="0" size="small" v-model="activeTab" type="editable-card" class="menu-tabs" @change="tabChange">
                <a-tab-pane v-for="tab in openTabs" :key="tab.meta.sign" :tab="tab.meta.title">
                    <a-layout-content class="content-outer">
                        <component :is="tab.component"></component>
                    </a-layout-content>
                </a-tab-pane>
            </a-tabs>
            <a-layout-content class="content-outer">
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import SubMenu from "../components/SubMenu";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        SubMenu,
    },
    data() {
        return {
            collapsed: false,
            keepAlive: true,
        };
    },
    computed: {
        ...mapGetters(["user", "openTabs", "menus", 'allow_menus']),
        openKeys() {
            let matched = this.$route.matched;
            if (matched.length > 2) {
                return [matched[1].meta.sign];
            }
            return [];
        },
        breadcrumb() {
            return {
                routes: this.$route.matched.concat().map((route) => {
                    return {
                        path: route.path,
                        breadcrumbName: route.meta.title || "🏠",
                    };
                }),
                itemRender: function ({ route, params, routes, paths }) {
                    return routes.indexOf(route) === routes.length - 1 ? (
                        <span>{route.breadcrumbName}</span>
                    ) : (
                        <router-link to={{ path: route.path || "/", params }}>
                            {route.breadcrumbName}
                        </router-link>
                    );
                },
            };
        },
        activeTab: {
            get() {
                return this.$store.state.local.activeTab;
            },
            set(value) {
                this.$store.commit("setActiveTab", value);
            },
        },
    },
    created() {
        if (this.user) {
            this.init();
        } else {
            this.$router.push("login");
        }
    },
    methods: {
        ...mapActions(["logout", "init"]),
        refresh() {
            location.href = "/";
        },
        reload() {
            this.keepAlive = false;
            this.$nextTick(() => {
                this.keepAlive = true;
            });
        },
        exit() {
            this.$confirm({
                title: "是否确认退出系统？",
                onOk: this.logout,
            });
        },
        tabChange(sign) {
            let tab = this.openTabs.find((i) => i.meta.sign == sign);
            if (tab) {
                this.$router.push(tab.path);
            }
        },
    },
};
</script>

<style scoped lang="less">
.ant-menu-item,
.ant-menu-submenu {
    user-select: none;
}

.header {
    padding: 0 26px;
    line-height: unset !important;
    background: #fff;
    height: 50px;
    border-bottom: 1px solid @border-color-base;
}

.sider {
    height: 100vh;
}

.sider-menu {
    overflow: hidden auto;
    max-height: calc(100vh - 112px);
}

.sider-menu::-webkit-scrollbar {
    display: none;
}

.brand {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 64px;

    .brand-title {
        color: white;
        font-size: 20px;
        font-weight: 800;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 7px;
        image-rendering: pixelated;
    }
}

.user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-outer {
    padding: 12px;
    background: #f0f2f5;
    overflow-x: hidden;
    // height: calc(100vh - 64px);
    height: calc(100vh - 100px);
    // border-top: 1px solid @border-color-base;
}

.menu-tabs {
    background: white;

    /deep/ .ant-tabs-bar {
        margin: unset;
    }
}
</style>