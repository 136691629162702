<template>
    <table-page>
        <template slot="header-extra">
            <a-form-model layout="inline" :model="form">
                <a-form-model-item label="用户">
                    <a-input v-model="form.username" placeholder="请输入用户名搜索" />
                </a-form-model-item>
                <a-form-model-item label="店铺" v-if="0">
                    <a-input v-model="form.shop" placeholder="请输入店铺名搜索" />
                </a-form-model-item>
                <a-form-model-item label="其他">
                    <a-input v-model="form.keyword" placeholder="请输入其他搜索条件" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="refresh(true)" htmlType="submit">查询</a-button>
                </a-form-model-item>
            </a-form-model>
        </template>
        <a-space direction="vertical" slot="header-footer">
            <a-space size="small" style="margin-bottom:10px">
                <add></add>
                <quick-add @close="refresh"></quick-add>
                <a-radio-group size="small" :default-value="order_state" v-model="form.state" button-style="solid"
                    @change="stateChange">
                    <a-radio-button :value="-1">全部</a-radio-button>
                    <a-radio-button v-for="(item, index) in order_status" :key="index" :value="item.key">{{
                        item.value
                    }}
                    </a-radio-button>
                </a-radio-group>
                <a-dropdown size="small">
                    <a-tooltip title="* 请选择相应状态进行更多操作">
                        <a-button size="small" type="primary">
                            批量操作
                            <a-icon type="down" />
                        </a-button>
                    </a-tooltip>
                    <a-menu slot="overlay">
                        <a-menu-item v-if="form.state === 0" @click="verify">
                            审核运单
                        </a-menu-item>
                        <a-menu-item v-if="form.state === 1" @click="print">
                            打印面单
                        </a-menu-item>
                        <a-menu-item v-if="form.state == 1" @click="transmit">
                            切换发货
                        </a-menu-item>
                        <a-menu-item v-if="form.state == 2" @click="deduct">
                            费用结算
                        </a-menu-item>
                        <a-menu-item v-if="form.state == 2" @click="returnSubmit">
                            退回提交
                        </a-menu-item>
                        <a-menu-item @click="reset">
                            重置订单
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </a-space>
        </a-space>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination"
            :row-selection="rowSelection" @change="change">
            <template slot="images" slot-scope="images">
                <preview-image :src="images"></preview-image>
            </template>
            <template slot="index" slot-scope="text,record">
                {{ data.indexOf(record) + 1 }}
            </template>
            <template slot="asin" slot-scope="text,record">
                <div v-if="record.goods">
                    <div v-for="(item, index) in record.goods" :key="index">{{ item.ASIN }}</div>
                </div>
            </template>
            <template slot="createTime" slot-scope="text,record">
                <a-space size="small" direction="vertical">
                    <span>{{ text }}</span>
                    <template v-if="record.state == 0">
                        <a-tag v-if="[3, 5].includes(days(text))" color="orange">{{ days(text) }}天未处理</a-tag>
                        <a-tag v-else-if="days(text) > 5" color="red">{{ days(text) }}天未处理</a-tag>
                    </template>
                </a-space>
            </template>
            <template slot="wayBillNumber" slot-scope="text,record">
                <a-space size="small" direction="vertical">
                    <a :href="'https://t.17track.net/zh-cn#nums=' + text" target="_blank">{{ text }}</a>
                    <a-tag color="red" v-if="record.isStoreShip">仓储发货</a-tag>
                </a-space>
            </template>
            <template slot="state" slot-scope="state">
                <a-tag v-if="state == 0">草稿</a-tag>
                <a-tag color="blue" v-if="state == 1">提交</a-tag>
                <a-tag color="green" v-if="state == 2">发货</a-tag>
                <a-tag color="cyan" v-if="state == 3">收货</a-tag>
                <a-tag color="pink" v-if="state == 4">拦截</a-tag>
                <a-tag color="red" v-if="state == 5">国际退回</a-tag>
                <a-tag color="red" v-if="state == 6">国内退回</a-tag>
                <a-tag color="#87d068" v-if="state == 7">签收</a-tag>
                <a-tag color="#87d068" v-if="state == 8">结算</a-tag>
                <a-tag v-if="state == 9">取消</a-tag>
            </template>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <a-dropdown size="small">
                        <a-button size="small" type="dashed">
                            更多
                            <a-icon type="down" />
                        </a-button>
                        <a-menu slot="overlay">
                            <a-menu-item @click="copy(record)">
                                申补订单
                            </a-menu-item>
                            <a-menu-item v-if="record.wayBillNumber && record.state == 1" @click="reverify(record)">
                                更换单号
                            </a-menu-item>
                            <a-menu-item v-if="record.state < 2">
                                <domestic-return :id="record.id">国内退件</domestic-return>
                            </a-menu-item>
                            <a-menu-item v-if="[2, 8].includes(record.state)" @click="orderReturn(record)">
                                国际退回
                            </a-menu-item>
                            <a-menu-item>
                                <add-stock :order="record">转库存</add-stock>
                            </a-menu-item>
                            <a-menu-item @click="showReverify(record)">
                                换单记录
                            </a-menu-item>
                            <a-menu-item @click="showRemark(record)">
                                查看备注
                            </a-menu-item>
                            <a-menu-item>
                                <add-work-server :order="record">申请工单</add-work-server>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <edit :id="record.id"></edit>
                    <a-popconfirm title="取消的订单不可恢复(只有草稿状态订单可取消)，是否确定？" @confirm="cancel(record)">
                        <a-button size="small" type="danger">取消</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </table-page>
</template>
<script>
import moment from "moment";
import add from "./Add.vue";
import edit from "./Edit.vue";
import QuickAdd from "@/components/QuickAdd";
import AddStock from "../../goods/stock/Add.vue";
import addWorkServer from "../../work-server/Add.vue";
import PreviewImage from "@/components/PreviewImage.vue";
import DomesticReturn from "./DomesticReturn.vue";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
    components: {
        AddStock,
        add,
        edit,
        QuickAdd,
        addWorkServer,
        DomesticReturn,
        PreviewImage,
    },
    data() {
        return {
            data: [],
            order_state: -1,
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            form: {},
            receive: {},
            selectedRows: [],
            selectedRowKeys: [],
        };
    },
    computed: {
        ...mapGetters(["platforms", "order_status"]),
        rowSelection() {
            let that = this;
            return {
                fixed: true,
                selectedRowKeys: that.selectedRowKeys,
                onChange(selectedRowKeys, selectedRows) {
                    that.selectedRowKeys = selectedRowKeys;
                    that.selectedRows = selectedRows;
                },
            };
        },
        columns() {
            let arr = [
                {
                    title: "序号",
                    dataIndex: "id",
                    scopedSlots: {
                        customRender: "index",
                    },
                },
                {
                    title: "图片",
                    dataIndex: "images",
                    scopedSlots: {
                        customRender: "images",
                    },
                },
                {
                    title: "订单编号",
                    dataIndex: "marketOrderId",
                },
                {
                    title: "国际单号",
                    dataIndex: "wayBillNumber",
                    scopedSlots: {
                        customRender: "wayBillNumber",
                    },
                },
                {
                    title: "旧国际单号",
                    dataIndex: "oldWayBillNumber",
                },
                {
                    title: "销售额",
                    dataIndex: "price",
                },
                {
                    title: "冻结资金",
                    dataIndex: "freezeFee",
                },
                {
                    title: "国际物流费",
                    dataIndex: "shipFee",
                },
                {
                    title: "国家",
                    dataIndex: "country",
                },
                {
                    title: "状态",
                    dataIndex: "state",
                    scopedSlots: {
                        customRender: "state",
                    },
                },
                {
                    title: "产品数量",
                    dataIndex: "goodsNum",
                },
                {
                    title: "所属人",
                    dataIndex: "username",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    scopedSlots: {
                        customRender: "createTime",
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ];
            if (this.util.hasRole(["SYSTEM", "ADMIN"])) {
                arr.ins;
                arr.splice(3, 0, {
                    title: "ASIN",
                    dataIndex: "goods",
                    scopedSlots: {
                        customRender: "asin",
                    },
                });
            }
            return arr;
        },
    },
    created() {
        this.form.state = this.order_state = parseInt(
            this.$route.query.state || -1
        );
        this.form.keyword = this.$route.query.keyword;
        this.refresh(true);
        let receiveId = this.$route.query.receiveId;
        if (receiveId) {
            let that = this;
            let key = `open${Date.now()}`;
            this.$notification.warn({
                key,
                duration: null,
                message: "物流认领",
                description:
                    "勾选需要赋值的订单，再点击附加按钮，可把改国内单号附加到订单国内物流单号上。",

                btn: (h) => {
                    return h(
                        "a-button",
                        {
                            props: {
                                type: "primary",
                            },
                            on: {
                                click: () => {
                                    that.attachPostId(receiveId);
                                    // this.$notification.close(key);
                                },
                            },
                        },
                        "附加单号"
                    );
                },
            });
        }
    },
    methods: {
        days(time) {
            return moment(new Date()).diff(moment(time), "days");
        },
        refresh(reset = false) {
            this.selectedRowKeys = []
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("order/search", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                    for (let item of this.data) {
                        item.goods = item.goods || [];
                        item.goodsNum = item.goods.length;
                    }
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        stateChange() {
            this.selectedRowKeys = []
            this.refresh();
        },
        showRemark(record) {
            this.$info({
                title: "备注信息",
                content: record.remark || "暂无备注信息",
                maskClosable: true,
            });
        },
        showReverify(record) {
            this.api
                .post("order/reverifyHistory", { id: record.id })
                .then((res) => {
                    let arr = res.data;
                    let text = "暂无换单记录";
                    if (arr && arr.length > 0) {
                        text =
                            "<table border='1' cellpadding='10'><tr><th>时间</th><th>旧单号</th><th>新单号</th></tr>";
                        for (let item of arr) {
                            text += `<tr><td>${item.createTime}</td><td>${item.remark.oldWayBillNumber}</td><td>${item.remark.newWayBillNumber}</td></tr>`;
                        }
                        text += "</table>";
                    }
                    this.$info({
                        title: "换单记录",
                        content: <div domPropsInnerHTML={text}></div>,
                        maskClosable: true,
                        width: "30%",
                    });
                });
        },
        orderReturn(record) {
            let that = this;
            this.$confirm({
                title: "提示",
                content: "是否确认操作国际退回?",
                onOk() {
                    that.api
                        .get("order/globalReturn?id=" + record.id)
                        .then(() => {
                            that.$message.info("退回成功");
                        });
                },
            });
        },
        copy(record) {
            let that = this;
            this.$confirm({
                title: "提示",
                content: "是否确认申补订单?",
                onOk() {
                    that.api.get("order/copy/" + record.id).then(() => {
                        that.$message.info("订单申补成功");
                        that.refresh();
                    });
                },
            });
        },
        print() {
            this.api
                .post("dispatch/print", this.selectedRowKeys)
                .then((res) => {
                    for (let item of res.data) {
                        window.open(item);
                    }
                });
        },
        verify() {
            for (let record of this.selectedRows) {
                this.api.get("dispatch/verify/" + record.id).then((res) => {
                    for (let i = 0; i < this.data.length; i++) {
                        let item = this.data[i];
                        if (item.id == res.data.id) {
                            Vue.set(this.data, i, res.data);
                            return;
                        }
                    }
                });
            }
        },
        reverify(record) {
            let that = this;
            this.$confirm({
                title: "提醒",
                content: "更换单号需扣除部分费用，是否确认更换？",
                onOk() {
                    that.api
                        .get("dispatch/reverify/" + record.id)
                        .then((res) => {
                            that.setRecord(res.data);
                        });
                },
            });
        },
        transmit() {
            for (let record of this.selectedRows) {
                this.api.post("dispatch/transmit/" + record.id).then((res) => {
                    this.$message.info(
                        `[${record.wayBillNumber}]订单状态切换成功`
                    );
                    this.setRecord(res.data);
                });
            }
        },
        deduct() {
            for (let record of this.selectedRows) {
                this.api.post("order/deduct", { id: record.id }).then((res) => {
                    this.$message.info(
                        `[${record.wayBillNumber}]订单费用结算成功`
                    );
                    this.setRecord(res.data);
                });
            }
        },
        returnSubmit() {
            for (let record of this.selectedRows) {
                this.api
                    .post("order/returnSubmit", { id: record.id })
                    .then((res) => {
                        this.$message.info(
                            `[${record.wayBillNumber}]订单退回提交成功`
                        );
                        this.setRecord(res.data);
                    });
            }
        },
        reset() {
            let that = this;
            this.$confirm({
                title: "提醒",
                content: "是否确认重置所选订单？",
                onOk() {
                    for (let record of that.selectedRows) {
                        that.api.get(`order/reset/${record.id}`).then((res) => {
                            that.$message.info(
                                `[${record.marketOrderId}]订单退回提交成功`
                            );
                            that.setRecord(res.data);
                        });
                    }
                },
            });
        },
        cancel(record) {
            let that = this;
            this.$confirm({
                title: "提醒",
                content: "是否确认取消所选订单？",
                onOk() {
                    that.api
                        .post("order/cancel", { id: record.id })
                        .then((res) => {
                            for (let i = 0; i < that.data.length; i++) {
                                let item = that.data[i];
                                if (item.id == res.data.id) {
                                    Vue.set(that.data, i, res.data);
                                    return;
                                }
                            }
                        });
                },
            });
        },
        setRecord(record) {
            for (let i = 0; i < this.data.length; i++) {
                let item = this.data[i];
                if (item.id == record.id) {
                    Vue.set(this.data, i, record);
                    return;
                }
            }
        },
        attachPostId(receiveId) {
            let rows = this.selectedRows;
            if (rows && rows.length) {
                this.api
                    .post("order/attachPostId", {
                        receiveId,
                        ids: rows.map((i) => i.id),
                    })
                    .then((res) => {
                        this.$message.info(`操作成功`);
                    });
            } else {
                this.$message.warning(`请勾选订单再次操作`);
            }
        },
    },
};
</script>
<style scoped>
.ant-table-tbody .ant-table-row {
    height: 20px;
}
</style>