<template>
    <base-page>
        <template slot="header-extra">
            <a-input-search placeholder="输入关键词搜索" v-model="form.keyword" enter-button @search="refresh(true)" />
        </template>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <template slot="state" slot-scope="state">
                <a-tag v-if="state == 0" color="orange">待付款</a-tag>
                <a-tag v-else-if="state == 1" color="red">取消</a-tag>
                <a-tag v-else-if="state == 2" color="green">成功</a-tag>
            </template>
            <template slot="index" slot-scope="text,record">
                {{data.indexOf(record)+1}}
            </template>
        </a-table>
    </base-page>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            data: [],
            columns: [
                {
                    title: "序号",
                    dataIndex: "id",
                    scopedSlots: {
                        customRender: "index",
                    },
                },
                {
                    title: "系统单号",
                    dataIndex: "tradeNo",
                },
                {
                    title: "金额",
                    dataIndex: "totalAmount",
                },
                {
                    title: "平台",
                    dataIndex: "platform",
                },
                {
                    title: "状态",
                    dataIndex: "state",
                    scopedSlots: {
                        customRender: "state",
                    },
                },
                {
                    title: "用户",
                    dataIndex: "username",
                },
                {
                    title: "下单时间",
                    dataIndex: "createTime",
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
        };
    },
    created() {
        this.refresh(true);
    },
    methods: {
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("wallet/recharge/search", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
    },
};
</script>
