<template>
    <base-page>
        <template slot="header-extra">
            <a-input-search placeholder="输入关键词搜索" v-model="form.keyword" enter-button @search="refresh(true)" />
        </template>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <a-dropdown>
                <a-menu slot="overlay">
                    <a-menu-item @click="auth('us-east-1')">北美</a-menu-item>
                    <a-menu-item @click="auth('eu-west-1')">欧洲</a-menu-item>
                    <a-menu-item @click="auth('us-west-2')">远东</a-menu-item>
                </a-menu>
                <a-button size="small" type="primary">授权</a-button>
            </a-dropdown>
            <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
        </a-space>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <edit :shop="record" @close="refresh"></edit>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import edit from "./Edit.vue";
export default {
    components: { edit },
    data() {
        return {
            data: [],
            form: {},
            columns: [
                {
                    title: "名称",
                    dataIndex: "name",
                },
                {
                    title: "卖家编号",
                    dataIndex: "sellerId",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "修改时间",
                    dataIndex: "updateTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
        };
    },
    created() {
        this.refresh(true);
    },
    methods: {
        add() {},
        edit() {},
        del(record) {
            this.api.delete(`shop/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api.search("shop", this.pagination, this.form).then((res) => {
                this.data = res.data.records || [];
                this.pagination.total = res.data.total;
            });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        auth(region) {
            this.api.get(`amazon/auth?region=${region}`).then((res) => {
                console.log("授权信息", res);
                window.open(res.data);
            });
        },
    },
};
</script>
