<template>
    <base-page>
        <template slot="header-extra">
            <a-space>
                <a-select v-model="form.state" placeholder="请选择状态" allowClear style="width:160px">
                    <a-select-option :value="0">未认领</a-select-option>
                    <a-select-option :value="1">已认领</a-select-option>
                    <a-select-option :value="2">已处理</a-select-option>
                    <a-select-option :value="3">已完结</a-select-option>
                </a-select>
                <a-input-search placeholder="输入关键词搜索" v-model="form.keyword" enter-button @search="refresh(true)" />
            </a-space>
        </template>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]'></add>
            <a-popconfirm title="是否确定删除？" @confirm="del(record)" v-if="0">
                <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
        </a-space>
        <a-alert message="在右上角修改状态并点击搜索查看相应订单（删除状态选择可查看全部）。" type="warning" show-icon closable></a-alert>
        <br>
        <a-modal title="标签打印" :visible="printVisible" @cancel="printVisible=false" destroyOnClose>
            <div id="receive-print" style="margin:auto;display:table;border:1px solid black;">
                <div style="margin:10px 0px 0px 10px;font-weight: bold">
                    <div>用户：{{printData.attach&&printData.attach.username||'--'}}</div>
                    <div>日期：{{printData.createTime}}</div>
                </div>
                <h1 style="margin:auto;display: table;font-weight: bold;font-size:xxx-large;">{{printData.shelves}}</h1>
                <barcode :value="printData.postId" :width="1" :height="30" style="margin:auto;display:table;"></barcode>
            </div>
            <template slot="footer">
                <a-button type="primary" v-print="printOptions">打印</a-button>
            </template>
        </a-modal>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <template slot="state" slot-scope="state">
                <a-tag v-if="state == 0">未认领</a-tag>
                <a-tag v-if="state == 1" color="blue">已认领</a-tag>
                <a-tag color="green" v-if="state == 2">已处理</a-tag>
                <a-tag color="purple" v-if="state == 3">已完结</a-tag>
            </template>
            <template slot="type" slot-scope="type">
                <a-tag color="#f50" v-if="type == 0">无头件</a-tag>
                <a-tag color="#2db7f5" v-if="type == 1">物流多发</a-tag>
                <a-tag color="#87d068" v-if="type == 2">国际退货</a-tag>
                <a-tag color="#108ee9" v-if="type == 3">商家发错</a-tag>
                <a-tag color="#faad14" v-if="type == 4">商品购买</a-tag>
            </template>
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <a-dropdown size="small">
                        <a-button size="small" type="dashed">
                            更多
                            <a-icon type="down" />
                        </a-button>
                        <a-menu slot="overlay">
                            <a-menu-item v-if="record.state == 0">
                                <edit :id="record.id" :userId="record.userId">申请认领</edit>
                            </a-menu-item>
                            <a-menu-item v-if="record.state == 2" v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]' @click="finish(record)">
                                处理完结
                            </a-menu-item>
                            <a-menu-item v-if="record.state == 1" @click="toOrder(record)">
                                跳转订单
                            </a-menu-item>
                            <a-menu-item v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]' @click="openPrint(record)">
                                打印标签
                            </a-menu-item>
                            <a-menu-item>
                                <show :id="record.id" :userId="record.userId" v-role='["SYSTEM", "WAREHOUSE_ADMIN", "WAREHOUSE_USER"]'>查看详情</show>
                            </a-menu-item>
                            <a-menu-item>
                                <add-work-server :receive="record">申请工单</add-work-server>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import Vue from "vue";
import Barcode from "vue-barcode";
import add from "./Add.vue";
import edit from "./Edit.vue";
import show from "./Show.vue";
import addWorkServer from "../../work-server/Add.vue";
export default {
    components: { add, edit, show, Barcode, addWorkServer },
    data() {
        return {
            data: [],
            form: {
                state: 1,
            },
            columns: [
                {
                    title: "国内单号",
                    dataIndex: "postId",
                },
                {
                    title: "货架号",
                    dataIndex: "shelves",
                },
                {
                    title: "类型",
                    dataIndex: "type",
                    scopedSlots: {
                        customRender: "type",
                    },
                },
                {
                    title: "状态",
                    dataIndex: "state",
                    scopedSlots: {
                        customRender: "state",
                    },
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "修改时间",
                    dataIndex: "updateTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
            printData: {},
            printVisible: false,
            printOptions: {
                id: "receive-print",
            },
        };
    },
    created() {
        this.refresh(true);
    },
    methods: {
        add() {},
        edit() {},
        del(record) {
            this.api.delete(`receive/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("receive", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        finish(record) {
            this.api.post(`receive/make/${record.id}`).then((res) => {
                for (let i = 0; i < this.data.length; i++) {
                    let item = this.data[i];
                    if (item.id == res.data.id) {
                        Vue.set(this.data, i, res.data);
                        return;
                    }
                }
            });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        toOrder(record) {
            this.$router.push({
                path: "/distribute/order",
                query: { receiveId: record.id },
            });
        },
        openPrint(record) {
            this.api.get(`receive/${record.id}`).then((res) => {
                this.printData = res.data;
                this.printVisible = true;
            });
        },
    },
};
</script>
