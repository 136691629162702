import store from '@/store'
export default {
    inserted(el, bindling, vnode, prevVnode) {
        let value = bindling.value;
        let role = store.getters.roleTag;
        let has = false;
        if (value.constructor === Array) {
            has = value.includes(role)
        } else if (value.constructor === String) {
            has = value == role
        }
        if (!has) {
            let comment = document.createComment(' ');
            Object.defineProperty(comment, 'setAttribute', {
                value: undefined,
            });
            vnode.elm = comment;
            vnode.text = '';
            vnode.isComment = true;
            // vnode.tag = vnode.context = vnode.data.directives == undefined;
            if (vnode.componentInstance) {
                vnode.componentInstance.$el = comment;
            }
            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }
        }
    }
}
