<template >
    <div v-if="loading" class="loading">
        <a-icon type="loading" class="loading-icon" />
        <div class="loading-text">{{tip}}</div>
    </div>
</template>
<script>
export default {
    name: 'LoadingMask',
    props: {
        spinning: {
            type: Boolean,
            require: true
        },
        delay: {
            type: Number,
            default: 0
        },
        tip: {
            type: String,
            default: '正在努力加载中，请稍后...'
        }
    },
    data() {
        return {
            tempLoading: false,
            loading: false
        }
    },
    watch: {
        spinning(val) {
            this.tempLoading = val;
            if (val) {
                setTimeout(() => {
                    if (this.tempLoading == val) {
                        this.loading = val;
                    }
                }, this.delay);
            } else {
                this.loading = val;
            }
        }
    }
};
</script>

<style scoped lang="less">
.loading {
    position: fixed;
    z-index: 2147483647;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    background: #00000059;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-icon {
        color: #00ff2a;
        font-size: 36px;
        margin-bottom: 15px;
    }

    .loading-text {
        background-image: -webkit-linear-gradient(left, #0000ff, #00ff2a);
        color: transparent;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        background-size: 300%;
        user-select: none;
        animation: loading-text 0.5s infinite linear;
        @keyframes loading-text {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: -100% -100%;
            }
        }
    }
}
</style>