<template>
    <div>
        <div @click="edit">
            <slot>
                <a-button size="small" type="primary">申请认领</a-button>
            </slot>
        </div>
        <a-modal title="认领" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="运单号">
                    {{receive.postId}}
                </a-form-model-item>
                <a-form-model-item label="完整单号" prop="postId">
                    <a-input v-model="form.postId" :disabled="finish" />
                </a-form-model-item>
                <a-form-model-item label="卖家姓名" prop="name">
                    <a-input v-model="form.name" :disabled="finish" />
                </a-form-model-item>
                <a-form-model-item label="卖家手机" prop="phone">
                    <a-input v-model="form.phone" :disabled="finish" />
                </a-form-model-item>
                <a-form-model-item label="卖家地址" prop="address">
                    <a-textarea v-model="form.address" :rows="4" :disabled="finish" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            require: true,
        },
        userId: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            roles: [],
            receive: {},
            form: {},
            rules: {
                postId: [
                    {
                        required: true,
                        message: "请输入完整单号",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "卖家联系方式必填",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "卖家地址必填",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "卖家姓名必填",
                    },
                ],
            },
        };
    },
    computed: {
        finish() {
            return this.userId > 0;
        },
    },
    methods: {
        edit() {
            this.api.get(`receive/${this.id}`).then((res) => {
                this.receive = res.data;
                this.visible = true;
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.id = this.id;
                    this.api.post("receive/apply", this.form).then(() => {
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                        this.visible = false;
                    });
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        }
    },
};
</script>
