<template>
    <base-page>
        <a-tabs class="tabs" v-model="type" default-active-key="1" tabPosition="left" @change="changeTab">
            <a-tab-pane key="general" tab="基本设置">
                <a-form-model ref="form" :model="current" :label-col="{span:4}" :wrapper-col="{span:14}">
                    <a-form-model-item label="用户名">
                        <a-input v-model="current.username" disabled />
                    </a-form-model-item>
                    <a-form-model-item label="手机号" extra="如需修改，请前往安全设置。">
                        <a-input v-model="current.phone" disabled />
                    </a-form-model-item>
                    <a-form-model-item label="昵称">
                        <a-input v-model="current.nickname" />
                    </a-form-model-item>
                    <a-form-model-item label="密码">
                        <a-input-password v-model="current.password" placeholder="不修改请留空" />
                    </a-form-model-item>
                    <a-form-model-item label="标记">
                        <a-input v-model="current.shortId" disabled />
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                        <a-button type="primary" @click="update">确认修改</a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-tab-pane>
            <a-tab-pane key="security" tab="安全设置">
                <a-list>
                    <a-list-item>
                        <a-list-item-meta title="绑定手机">
                            <span slot="description">已绑定手机：{{current.phone}}</span>
                        </a-list-item-meta>
                        <a slot="actions" @click="phoneVisible=true">修改</a>
                    </a-list-item>
                </a-list>
            </a-tab-pane>
        </a-tabs>
        <a-modal title="换绑手机" :visible="phoneVisible" :confirm-loading="phoneLoading" @ok="updatePhone" @cancel="phoneVisible=false">
            <a-form-model ref="modify" :model="modify" :rules="rules">
                <a-form-model-item prop="oldPhone" label="旧手机号">
                    <a-input v-model="modify.oldPhone" placeholder="请输入当前手机号" allowClear />
                </a-form-model-item>
                <a-form-model-item prop="phone" label="新手机号">
                    <a-input v-model="modify.phone" placeholder="请输入新手机号" allowClear />
                </a-form-model-item>
                <a-form-model-item prop="code" label="验证码">
                    <a-input-search v-model="modify.code" placeholder="请输入验证码" allow-clear @search="sendCode('modify',modify.phone)">
                        <a-button slot="enterButton" type="primary" :loading="smsLoading">{{ sendSmsBtn }}</a-button>
                    </a-input-search>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </base-page>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            type: this.$route.query.type || "general",
            current: {},
            modify: {},
            phoneVisible: false,
            smsLoading: false,
            phoneLoading: false,
            sendSmsBtn: "获取验证码",
            rules: {
                oldPhone: [
                    {
                        required: true,
                        message: "旧手机号必填",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "新手机号必填",
                        trigger: "change",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "验证码必填",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    created() {
        this.refresh();
    },
    methods: {
        ...mapActions(["smsCode"]),
        add() {},
        del() {},
        refresh() {
            this.api.get(`user/${this.user.id}`).then((res) => {
                this.current = res.data;
            });
        },
        update() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("user", this.current).then((res) => {
                        this.current = res.data;
                        this.$message.success("修改成功");
                        this.$refs.form.resetFields();
                    });
                }
            });
        },
        async sendCode(type, phone) {
            await this.smsCode({
                mobile: phone,
                type: type,
            });
            let delay = 60;
            let sendSmsBtn = this.sendSmsBtn;
            this.smsLoading = true;
            this.sendSmsBtn = `${delay}s`;
            let interval = setInterval(() => {
                if (delay > 1) {
                    delay--;
                    this.sendSmsBtn = `${delay}s`;
                } else {
                    clearInterval(interval);
                    this.smsLoading = false;
                    this.sendSmsBtn = sendSmsBtn;
                }
            }, 1000);
        },
        updatePhone() {
            this.$refs.modify.validate((valid) => {
                if (valid) {
                    this.api
                        .post("user/modifyPhone", this.modify)
                        .then((res) => {
                            this.current = res.data;
                            this.$message.success("修改成功");
                            this.$refs.modify.resetFields();
                            this.phoneVisible = false;
                        });
                }
            });
        },
        changeTab(e) {
            this.$router.push(`/authorize/user-profile?type=${e}`);
        },
    },
};
</script>
<style scoped lang="less">
* {
    border-radius: 0 !important;
}
.tabs {
    /deep/ .ant-tabs-tab-active {
        background-color: #e6f7ff;
    }
}
</style>
