<template>
    <div>
        <a-button size="small" type="primary" @click="edit">编辑</a-button>
        <a-modal title="添加" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left" :label-col="{span:4}" :wrapper-col="{span:18,offset:2}">
                <a-form-model-item label="仓库名称" prop="title">
                    <a-input v-model="form.title" placeholder="请输入仓库名称" />
                </a-form-model-item>
                <a-form-model-item label="发件人姓名" prop="name">
                    <a-input v-model="form.name" placeholder="请输入发件人姓名" />
                </a-form-model-item>
                <a-form-model-item label="省份" prop="province">
                    <a-input v-model="form.province" placeholder="请输入省份" />
                </a-form-model-item>
                <a-form-model-item label="城市" prop="city">
                    <a-input v-model="form.city" placeholder="请输入城市" />
                </a-form-model-item>
                <a-form-model-item label="街道" prop="street">
                    <a-textarea v-model="form.street" placeholder="请输入街道" />
                </a-form-model-item>
                <a-form-model-item label="邮编" prop="zip">
                    <a-input v-model="form.zip" placeholder="请输入邮编" />
                </a-form-model-item>
                <a-form-model-item label="联系方式" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入联系方式" />
                </a-form-model-item>
                <a-form-model-item label="邮箱" prop="email">
                    <a-input v-model="form.email" placeholder="请输入邮箱" />
                </a-form-model-item>
                <a-form-model-item label="备注" prop="remark">
                    <a-textarea v-model="form.remark" placeholder="请输入备注" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        id: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            form: {},
            rules: {
                title: [
                    {
                        required: true,
                        message: "仓库名称必填",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "发件人姓名必填",
                    },
                ],
                province: [
                    {
                        required: true,
                        message: "省份必填",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "城市必填",
                    },
                ],
                street: [
                    {
                        required: true,
                        message: "街道必填",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "联系方式必填",
                    },
                ]
            }
        };
    },
    computed: {
        ...mapGetters(['platforms'])
    },
    methods: {
        edit() {
            this.api.get(`warehouse/${this.id}`).then(res => {
                this.form = res.data;
                this.visible = true;
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.api.put('warehouse', this.form).then(() => {
                        this.$message.success('修改成功');
                        this.$refs.form.resetFields();
                        this.visible = false;
                    })
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        }
    }
};
</script>
