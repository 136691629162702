<template>
    <div>
        <div @click="edit">
            <slot>
                <a-button size="small" type="primary">详情</a-button>
            </slot>
        </div>
        <a-modal class="modal-form" title="申请工单" width="50%" :footer="null" :visible="visible" :confirm-loading="loading" :maskClosable="false" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
                <a-form-model-item label="国际物流单号" prop="tracking_number">
                    <a-input v-model="form.trackingNumber" placeholder="国际物流单号" disabled />
                </a-form-model-item>
                <a-form-model-item label="问题描述" prop="content">
                    <a-textarea placeholder="请输入问题描述" :rows="6" v-model="form.content" />
                </a-form-model-item>
                <a-form-model-item label="附件" prop="images">
                    <file-upload accept=".jpg,.png,.jpeg" listType="picture-card" multiple class="file-upload" v-model="form.images">
                        <a-icon type="upload" v-if="!form.images || form.images.length < 5" />
                    </file-upload>
                    <span slot="extra" style="color:red">* 最多可以上传5张图片</span>
                </a-form-model-item>
                <a-card size="small" title="消息">
                    <a-list size="small" v-if="form.message.length" :data-source="form.message" item-layout="horizontal">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <a-comment :content="item.text" :avatar="item.avatar" :datetime="item.time">
                                <h3 :style="{ color: user.id == item.uid?'green':'blue' }" slot="author">{{ item.username }}</h3>
                            </a-comment>
                        </a-list-item>
                    </a-list>
                    <div v-if="!form.state">
                        <a-textarea placeholder="请输入消息" :rows="6" v-model="input" />
                        <a-space style="float:right">
                            <a-popconfirm title="是否确认结束此工单?" @confirm="end">
                                <a-button type="danger">完结</a-button>
                            </a-popconfirm>
                            <a-button type="primary" @click="send">发送</a-button>
                        </a-space>
                    </div>
                </a-card>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import FileUpload from "@/components/FileUpload.vue";
import Vue from "vue";
export default {
    components: { FileUpload },
    props: {
        id: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            input: "",
            form: {
                tracking_number: "",
                images: [],
                message: [],
                content: "",
                tag: "",
            },
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        edit() {
            this.api.get(`work_server/${this.id}`).then((res) => {
                res.data.images = res.data.images || [];
                res.data.message = res.data.message || [];
                this.form = res.data;
                this.visible = true;
            });
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.api.put("work_server", this.form).then((res) => {
                        this.$message.success("提交成功");
                        this.input = "";
                        this.form = res.data;
                    });
                }
            });
        },
        send() {
            if (this.input) {
                this.form.message.push({
                    text: this.input,
                    time: this.util.format(Date.now(), "Y年M月D日 h:m:s"),
                    uid: this.user.id,
                    username: this.user.username,
                    avatar: "/logo.png",
                });
                this.submit();
            }
        },
        cancel() {
            this.$refs.form.resetFields();
            this.visible = false;
        },
        end() {},
    },
};
</script>
<style scoped lang="less">
.ant-select {
    min-width: 180px;
}

.ant-form-item {
    margin-right: unset;
}

.file-upload {
}

.post-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tag {
        margin-bottom: 5px;
        user-select: none;
    }
}

.tags {
    /deep/ .ant-select-selection__choice {
        border: unset;
        background-color: unset;
        padding: unset;
    }

    /deep/ .ant-tag {
        margin-right: 0px;
        padding: 0px 20px 0px 7px;
    }
}
</style>