import axios from "axios";
import { notification } from 'ant-design-vue';

import router from '../router';
import { loading } from './ui';
import config from "./config";

const API_PATH = config.VUE_APP_API;

const api = {
    login(params = {}) {
        return this.post('login', params);
    },
    register(params = {}) {
        return this.post('register', params);
    },
    resetPwd(params = {}) {
        return this.post('resetPwd', params);
    },
    logout() {
        return this.get('logout');
    },
    smsCode(mobile, type) {
        return this.get(`sms?type=${type}&mobile=${mobile}`);
    },
    page(url, page, count, params = {}) {
        return this.get(`${url}?page=${page}&count=${count}`, params);
    },
    search(url, pagination, params = {}) {
        return this.post(url, {
            current: pagination.current,
            size: pagination.pageSize,
            total: pagination.total,
            data: params
        });
    },
    download(url, params = {}) {
        return this.request({
            url: url,
            data: params,
            responseType: 'blob',
            method: "POST"
        }).then(response => {
            if (response.headers['content-type'].includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                let filename = response.headers['content-disposition'];
                if (filename) {
                    let reg = /filename\*=UTF-8\'\'(.*?)+/g.exec(filename)
                    filename = reg[0].replace("filename*=UTF-8''", '');
                }
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(response.data);
                link.download = decodeURI(filename);
                link.click();
                window.URL.revokeObjectURL(link.href);
            }
            return response
        });
    },
    get(url, params = {}) {
        return this.request({
            url: url,
            params: params,
            method: "GET"
        });
    },
    post(url, params = {}) {
        return this.request({
            url: url,
            data: params,
            method: "POST"
        });
    },
    put(url, params = {}) {
        return this.request({
            url: url,
            data: params,
            method: "PUT",
        });
    },
    delete(url, params = {}) {
        return this.request({
            url: url,
            data: params,
            method: "DELETE"
        });
    },
    request(options) {
        return axios({
            ...options,
            baseURL: API_PATH,
            withCredentials: true,
            headers: {
                'Content-Type': "application/json;charset=UTF-8",
            }
        });
    }
};

export default api;

/**
 * 请求拦截器
 */
axios.interceptors.request.use(config => {
    console.log("%cNetwork准备请求：", "color:blue;font-weight:600;", config.url, config);
    loading(true);
    return config;
},
    err => {
        console.error(err);
        loading(false);
        return Promise.reject(err);
    }
);

/**
 * 响应拦截器
 */
axios.interceptors.response.use(response => {
    loading(false);
    console.log("%cNetwork请求完毕：", "color:green;font-weight:600;", response.config.url, response);
    if (response.headers['content-disposition']) {
        return response;
    } else if (response.status == 200 && response.data) {
        let data = response.data;
        if (data && !data.success) {
            notification.error({
                message: '错误',
                description: `${data.code}:${data.desc}`
            });
            return new Promise(() => { });
        }
    }
    return response.data;
},
    async err => {
        loading(false);
        if (err.response.status === 401) {
            api.logout()
            router.push("login");
        } else {
            console.error("%cNetwork请求错误：", "color:red;font-weight:600;", err.response, err);
            let data = err.response.data;
            if (data instanceof Blob) {
                data = JSON.parse(await data.text())
            }
            if (data) {
                notification.error({
                    message: '错误',
                    description: `${data.code}:${data.desc}`
                });
            }
            return Promise.reject(data);
        }
        return new Promise(() => { });
    }
);
