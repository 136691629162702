<template>
    <base-page>
        <template slot="header-extra">
            <a-input-search placeholder="输入关键词搜索" v-model="form.keyword" enter-button @search="refresh(true)" />
        </template>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="change">
            <template slot="payType" slot-scope="payType">
                <a-tag v-if="payType==0" color="orange">物流发货</a-tag>
                <a-tag v-else-if="payType==1" color="green">月租费用</a-tag>
                <a-tag v-else-if="payType==2" color="cyan">更换单号</a-tag>
                <a-tag v-else-if="payType==3" color="blue">系统扣除</a-tag>
                <a-tag v-else-if="payType==4" color="purple">打包费用</a-tag>
            </template>
            <template slot="index" slot-scope="text,record">
                {{data.indexOf(record)+1}}
            </template>
            <template slot="action" slot-scope="text, record">
                <a-space size="small">
                    <a-button size="small" type="dashed" @click="show(record)">查看</a-button>
                    <a-button size="small" type="primary" @click="edit(record)">编辑</a-button>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </template>
        </a-table>
    </base-page>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            data: [],
            columns: [
                {
                    title: "序号",
                    dataIndex: "id",
                    scopedSlots: {
                        customRender: "index",
                    },
                },
                {
                    title: "系统单号",
                    dataIndex: "tradeNo",
                },
                {
                    title: "订单号",
                    dataIndex: "marketOrderId",
                },
                {
                    title: "国际单号",
                    dataIndex: "wayBillNumber",
                },
                {
                    title: "金额",
                    dataIndex: "totalAmount",
                },
                {
                    title: "余额",
                    dataIndex: "money",
                },
                {
                    title: "消费类型",
                    dataIndex: "payType",
                    scopedSlots: {
                        customRender: "payType",
                    },
                },
                {
                    title: "用户",
                    dataIndex: "username",
                },
                {
                    title: "下单时间",
                    dataIndex: "createTime",
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["20", "50", "100", "200"],
            },
            rowSelection: {
                fixed: true,
            },
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh(reset = false) {
            if (reset) {
                this.pagination.current = 1;
            }
            this.api
                .search("wallet/pay/search", this.pagination, this.form)
                .then((res) => {
                    this.data = res.data.records || [];
                    this.pagination.total = res.data.total;
                });
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
    },
};
</script>
