<template>
    <div id="app">
        <a-config-provider :locale="zh_CN">
            <router-view></router-view>
        </a-config-provider>
        <loading-mask :delay="1000" :spinning="loading"></loading-mask>
    </div>
</template>

<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import { mapState } from "vuex";
import LoadingMask from "./components/LoadingMask.vue";
export default {
    components: {
        LoadingMask,
    },
    data() {
        return {
            zh_CN,
        };
    },
    computed: mapState(["loading"]),
};
</script>

<style lang="less">
#app {
    min-height: 100vh;
}

.ant-page-header {
    border-radius: 4px;
    margin-bottom: 15px;
    border: 1px solid #e8e8e8;
}

.v-select {
    display: inline-block;
}

.vs__clear {
    line-height: initial;
}

.vs__dropdown-menu {
    min-width: 100%;
    width: unset;
}
</style>
