<template>
    <base-page>
        <a-space slot="header-footer" size="small" style="margin-bottom:10px">
            <add></add>
            <a-popconfirm title="是否确定全部删除？" @confirm="del(record)">
                <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
        </a-space>
        <a-table bordered size="small" rowKey="id" :columns="columns" :data-source="data" :pagination="pagination"
            :row-selection="rowSelection" @change="change">
            <span slot="action" slot-scope="text, record">
                <a-space size="small">
                    <edit :id="record.id"></edit>
                    <a-popconfirm title="是否确定删除？" @confirm="del(record)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </base-page>
</template>
<script>
import add from './Add.vue';
import edit from './Edit.vue';
export default {
    components: { add, edit },
    data() {
        return {
            data: [],
            columns: [
                {
                    title: '仓库名称',
                    dataIndex: 'title'
                },
                {
                    title: '发件人姓名',
                    dataIndex: 'name'
                },
                {
                    title: '省份',
                    dataIndex: 'province'
                },
                {
                    title: '城市',
                    dataIndex: 'city'
                },
                {
                    title: '联系方式',
                    dataIndex: 'phone'
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 180,
                    scopedSlots: {
                        customRender: "action"
                    }
                }
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 20,
                size: 'small',
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['20', '50', '100', '200']
            },
            rowSelection: {
                fixed: true
            }
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        del(record) {
            this.api.delete(`warehouse/${record.id}`).then((res) => {
                this.data = this.data.filter((item) => item.id != record.id);
            });
        },
        refresh() {
            this.api.page('warehouse', this.pagination.current, this.pagination.pageSize).then(res => {
                this.data = res.data.records || [];
                this.pagination.total = res.data.total;
            })
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        }
    }
};
</script>
