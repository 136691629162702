import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import Print from 'vue-print-nb'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import App from './App.vue'

import BasePage from './components/BasePage.vue'
import TablePage from './components/TablePage.vue'

import store from './store'
import router from './router'
import config from './utils/config'
import api from './utils/api'
import util from './utils/util'

import Directives from "./directives";

Vue.use(Antd);
Vue.use(Print);
Vue.use(Directives)

Vue.config.productionTip = false

Vue.prototype.api = api;
Vue.prototype.util = util;

Vue.prototype.ENV = config

Vue.component('BasePage', BasePage)
Vue.component('TablePage', TablePage)
Vue.component('v-select', vSelect)

new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')
