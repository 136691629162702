<template>
    <base-page :header="false">
        <a-row :gutter="[15, 15]">
            <a-col>
                <a-alert message="操作提示" type="warning" show-icon closable>
                    <template slot="description">
                        <div>在左侧搜索出相应用户后在右侧操作进行充值。</div>
                        <b style="color:red">金额填正数为充值，负数为扣费。</b>
                    </template>
                </a-alert>
            </a-col>
        </a-row>
        <a-row :gutter="[5, 15]">
            <a-col span="12">
                <a-card title="上帝模式" size="small">
                    <a-row :gutter="[16, 24]">
                        <a-col>
                            <a-input-search placeholder="输入 用户名/手机号 进行搜索" enter-button @search="search" />
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 24]">
                        <a-col span="6">
                            <a-statistic title="用户" :value="user.username">
                                <template slot="prefix">
                                    <a-icon type="user" />
                                </template>
                            </a-statistic>
                        </a-col>
                        <a-col span="6">
                            <a-statistic title="手机号" :value="user.phone" groupSeparator="">
                                <template slot="prefix">
                                    <a-icon type="mobile" />
                                </template>
                            </a-statistic>
                        </a-col>
                        <a-col span="6">
                            <a-statistic title="余额" :value="user.money" :precision="2">
                                <template slot="prefix">￥</template>
                            </a-statistic>
                        </a-col>
                        <a-col span="6">
                            <a-statistic title="冻结资金" :value="user.freezeMoney" :precision="2" :value-style="{ color: 'red' }">
                                <template slot="prefix">￥</template>
                            </a-statistic>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
            <a-col span="12">
                <a-card title="充值操作" size="small">
                    <a-form-model ref="form" :model="form" labelAlign="left" :label-col="{ span: 2 }" :wrapper-col="{ span: 18, offset: 2 }">
                        <a-form-model-item label="用户" required>
                            <a-input v-model="user.username" disabled />
                        </a-form-model-item>
                        <a-form-model-item label="金额" prop="money" required>
                            <a-input-number v-model="form.money" :precision="2" style="width:100%"></a-input-number>
                        </a-form-model-item>
                        <a-form-model-item label="备注" prop="remark">
                            <a-textarea v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3}" />
                        </a-form-model-item>
                    </a-form-model>
                    <template slot="actions">
                        <a-button type="primary" @click="submit" :loading="loading">提交</a-button>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </base-page>
</template>

<script>
export default {
    data() {
        return {
            form: {
                money: 0,
            },
            user: {},
            loading: false,
        };
    },
    methods: {
        search(text) {
            if (text) {
                this.api
                    .post(`wallet/search`, {
                        phone: text,
                    })
                    .then((res) => {
                        this.user = res.data;
                        this.form.id = this.user.id;
                    });
            } else {
                this.$message.error("请输入关键词搜索");
            }
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.user.id) {
                        if (this.form.money != 0) {
                            this.loading = true;
                            this.api.post("wallet/god", this.form).then(() => {
                                this.search(this.user.phone);
                                this.$message.success("提交成功");
                                this.loading = false;
                                this.$refs.form.resetFields();
                            });
                        } else {
                            this.$message.error("金额不可为0");
                        }
                    } else {
                        this.$message.error("请搜索用户后再进行充值操作");
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
</style>
