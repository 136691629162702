<template>
    <base-page>
        <a-row type="flex" justify="center">
            <a-col :span="9">
                <a-input-search placeholder="输入关键词搜索" v-model="form.keyword" enter-button="搜索" size="large"
                    @search="search" />
            </a-col>
        </a-row>
        <br>
        <a-row type="flex" justify="center">
            <a-col :span="9">
                <a-radio-group v-model="searchType" button-style="solid">
                    <a-radio-button v-for="(item, index) in searchTypes" :key="index" :value="item.value">{{ item.name
                    }}</a-radio-button>
                </a-radio-group>
            </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-card>
            <template slot="title">
                <a-checkbox v-model="automate">自动处理</a-checkbox>
            </template>
            <a-table bordered size="small" :rowKey="(x) => x.id" :columns="columns" :data-source="data"
                :pagination="pagination" @change="change">
                <template slot="images" slot-scope="images">
                    <preview-image :src="images"></preview-image>
                </template>
                <template slot="marketOrderId" slot-scope="text,record">
                    <a-space direction="vertical">
                        <span>{{ text }}</span>
                        <a-tag color="red" v-if="record.err">{{ record.err }}</a-tag>
                    </a-space>
                </template>
                <template slot="wayBillNumber" slot-scope="text,record">
                    <a-space direction="vertical">
                        <a :href="'https://t.17track.net/zh-cn#nums=' + text" target="_blank">{{ text }}</a>
                        <a-tag color="red" v-if="record.isStoreShip">仓储发货</a-tag>
                    </a-space>
                </template>
                <template slot="goods" slot-scope="goods">
                    <a-space direction="vertical">
                        <div v-for="(item, index) in goods" :key="index">
                            <a-tag color="cyan" v-for="(it, i) in item.postIds" :key="i">
                                {{ it }}
                            </a-tag>
                        </div>
                    </a-space>
                </template>
                <template slot="goodRemark" slot-scope="goodRemark">
                    {{ goodRemark }}
                </template>
                <template slot="state" slot-scope="state">
                    <a-tag v-if="state == 0">草稿</a-tag>
                    <a-tag color="blue" v-if="state == 1">提交</a-tag>
                    <a-tag color="green" v-if="state == 2">发货</a-tag>
                    <a-tag color="cyan" v-if="state == 3">收货</a-tag>
                    <a-tag color="pink" v-if="state == 4">拦截</a-tag>
                    <a-tag color="red" v-if="state == 5">国际退回</a-tag>
                    <a-tag color="red" v-if="state == 6">国内退回</a-tag>
                    <a-tag color="#87d068" v-if="state == 7">签收</a-tag>
                    <a-tag color="#87d068" v-if="state == 8">结算</a-tag>
                    <a-tag v-if="state == 9">取消</a-tag>
                </template>
                <a-space size="small" slot="action" slot-scope="text, record">
                    <a-popconfirm title="是否确认回退状态？" @confirm="returnSubmit(record)">
                        <a-button size="small">退回提交</a-button>
                    </a-popconfirm>
                    <edit :id="record.id"></edit>
                    <a-dropdown size="small">
                        <a-button size="small" type="dashed">
                            更多
                            <a-icon type="down" />
                        </a-button>
                        <a-menu slot="overlay">
                            <a-menu-item @click="print(record)">
                                打印面单
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </a-space>
            </a-table>
        </a-card>
    </base-page>
</template>
<script>
import { mapGetters } from "vuex";
import edit from "@/views/distribute/order/Edit.vue";
import PreviewImage from "@/components/PreviewImage.vue";
import Vue from "vue";
export default {
    components: { edit, PreviewImage },
    data() {
        return {
            searchType: "LOGISTICS",
            searchTypes: [
                {
                    name: "物流件",
                    value: "LOGISTICS",
                },
                {
                    name: "库存件",
                    value: "STOCK",
                },
                {
                    name: "国际退回",
                    value: "GLOBAL_RETURN",
                },
            ],
            data: [],
            form: {
                keyword: "",
                state: 1,
            },
            automate: false,
            columns: [
                {
                    title: "图片",
                    dataIndex: "images",
                    scopedSlots: {
                        customRender: "images",
                    },
                },
                {
                    title: "订单号",
                    dataIndex: "marketOrderId",
                    scopedSlots: {
                        customRender: "marketOrderId",
                    },
                },
                {
                    title: "国际单号",
                    dataIndex: "wayBillNumber",
                    scopedSlots: {
                        customRender: "wayBillNumber",
                    },
                },
                {
                    title: "平台",
                    dataIndex: "platformName",
                },
                {
                    title: "渠道",
                    dataIndex: "method",
                },
                {
                    title: "国内运单号",
                    dataIndex: "goods",
                    scopedSlots: {
                        customRender: "goods",
                    },
                },
                {
                    title: "状态",
                    dataIndex: "state",
                    scopedSlots: {
                        customRender: "state",
                    },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                },
                {
                    title: "产品备注",
                    dataIndex: "goodRemark",
                    scopedSlots: {
                        customRender: "goodRemark",
                    },
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 180,
                    scopedSlots: {
                        customRender: "action",
                    },
                },
            ],
            pagination: {
                current: 1,
                total: 999,
                pageSize: 10,
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "100", "200"],
            },
        };
    },
    computed: {
        ...mapGetters(["platforms"]),
    },
    methods: {
        search() {
            this.refresh();
        },
        async refresh() {
            if (this.searchType == "LOGISTICS") {
                this.form.state = 1;
            } else if (this.searchType == "GLOBAL_RETURN") {
                this.form.state = 5;
            } else {
                this.form.state = 1;
            }
            let { data } = await this.api.search(`order/search?type=${this.searchType}`, this.pagination, this.form)
            let list = data.records || [];
            for (let item of list) {
                let goods = item.goods;
                let txt = "";
                for (let o of goods) {
                    if (o.remark) {
                        txt += o.remark + "\n";
                    }
                }
                item.goodRemark = txt || "";
            }

            this.data = list;
            this.pagination.total = data.total;

            if (this.automate) {
                for (let item of list) {
                    if (item.state == 1) {
                        await this.printAndTransmit(item);
                    }
                }
            }
        },
        change(pagination) {
            this.pagination = pagination;
            this.refresh();
        },
        print(record) {
            this.api.post("dispatch/print", [record.id]).then((res) => {
                for (let item of res.data) {
                    window.open(item);
                }
            });
        },
        async printAndTransmit(record) {
            let res = await this.api.get(`dispatch/printAndTransmit/${record.id}`);
            let result = res.data;
            if (result.err) {
                Vue.set(record, "err", result.err);
            } else if (result.urls && result.urls.length > 0) {
                record.state = 2
                for (let item of result.urls) {
                    window.open(item);
                }
            }
        },
        returnSubmit(record) {
            this.api
                .post("order/returnSubmit", { id: record.id })
                .then((res) => {
                    record.state = 0;
                });
        },
        setRecord(record) {
            for (let i = 0; i < this.data.length; i++) {
                let item = this.data[i];
                if (item.id == record.id) {
                    Vue.set(this.data, i, record);
                    return;
                }
            }
        },
    },
};
</script>
