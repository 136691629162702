import { message } from 'ant-design-vue';

import router from '../../router';
import api from '../../utils/api';

export default {
    state: {
        user: JSON.parse(localStorage.getItem('user'))
    },
    getters: {
        user(state) {
            return state.user;
        },
        roleTag(state) {
            return state.user.role.tag;
        },
        permissions(state) {
            return state.user.role.permissions || [];
        },
        allow_menus(state) {
            let menus = state.user && state.user.id && state.user.role && state.user.role.menus
            if (menus) {
                return menus
            } else {
                commit('set_user', null);
                router.push("/login");
            }
            return false;
        }
    },
    mutations: {
        set_user(state, user) {
            state.user = user;
            user ? localStorage.setItem('user', JSON.stringify(user)) : localStorage.removeItem('user');
        }
    },
    actions: {
        hasMenuWithSign({ state, commit }, sign) {
            let menus = state.user && state.user.id && state.user.role && state.user.role.menus
            if (menus) {
                return menus.includes(sign)
            } else {
                commit('set_user', null);
                router.push("/login");
            }
            return false;
        },
        async login({ state, commit }, args) {
            let { data } = await api.login(args);
            commit('set_user', data);
            message.success('登录成功');
            await router.push("/");
        },
        register({ }, args) {
            return new Promise((resolve, reject) => {
                api.register(args).then(() => {
                    resolve();
                    message.success('注册成功,请前往登录');
                }).catch(reject)
            });
        },
        resetPwd({ }, args) {
            return new Promise((resolve, reject) => {
                api.resetPwd(args).then(() => {
                    resolve();
                    message.success('新密码设置成功,请前往登录');
                }).catch(reject)
            });
        },
        smsCode({ }, args) {
            return api.smsCode(args.mobile, args.type);
        },
        async logout({ state, commit }) {
            await api.logout();
            commit('set_user', null);
            await router.push("/login")
            message.success('退出成功');
        }
    }
}